import { StoreProvider } from "../stores/index";

type Props = {
  children: JSX.Element;
};

const AllProviders = ({ children }: Props) => {
  return <StoreProvider>{children}</StoreProvider>;
};

export default AllProviders;
