import dayjs from "dayjs";
import { action, observable, makeObservable } from "mobx";
import { getMachineMetrics } from "../../api/machinesApis";
import {
  IMachineMetrics,
  IVisualizationFieldsObject,
} from "../../interfaces/machinesInterfaces";
import { ErrorReporter } from "../../libs/ErrorReporter";
import { MetricsTypes, durations } from "../../config/constants";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { generateTimestampArray } from "../../utils/generateTimestampArray";
import Logger from "../../libs/Logger";
import { generateUniformTimeStamps, getDefaultMetricsDuration } from "../../utils/services";

dayjs.extend(advancedFormat);

export type MetricsDataType = {
  metric: {
    state: string;
    device: any;
    machine_id: any;
    direction: any;
    type: any;
  };
  values: [number, any][];
}[];

interface IMetricsDuration  {
  label: string,
  value: string,
  durationDifference: number,
  interval: string,
  from: number,
  to: number,
  intervalValue: 60,
}

type IMachineMetricsFields = {
  isLoading: boolean;
  metrics: Partial<IMachineMetrics>;
  metricsDuration:IMetricsDuration;
};

const defaultMetricsDuration = getDefaultMetricsDuration();


const initialValues: IMachineMetricsFields = {
  isLoading: false,
  metrics: {
    start: dayjs().subtract(5, "minute").unix(),
    end: dayjs().unix(),
    visualization: {
      cpu: {
        type: "cpu",
        timeInterval: "60s",
        aggregateFunction: "",
        allowedIntervals: [],
      },
      network: {
        type: "network",
        timeInterval: "60s",
        aggregateFunction: "",
        allowedIntervals: [],
      },
      disk: {
        type: "disk",
        timeInterval: "60s",
        aggregateFunction: "",
        allowedIntervals: [],
      },
      file: {
        type: "file",
        timeInterval: "60s",
        aggregateFunction: "",
        allowedIntervals: [],
      },
      load: {
        type: "load",
        timeInterval: "60s",
        aggregateFunction: "",
        allowedIntervals: [],
      },
      memory: {
        type: "memory",
        timeInterval: "60s",
        aggregateFunction: "",
        allowedIntervals: [],
      },
    },
    aggregateFunc: "SUM",
  },
  metricsDuration: defaultMetricsDuration
};

class MetricesStore {
  isLoading = false;
  metrics: Partial<IMachineMetrics> = initialValues.metrics;
  interval = "60s";
  machineId = "";
  networkMetrics = [];
  cpuMetrics = [];
  diskMetrics = [];
  systemLoadMetrics = [];
  fileMetrics = [];
  memoryMetrics = [];
  isCpuMetricsLoading = true;
  isDiskMetricsLoading = true;
  isFileSystemMetricsLoading = true;
  isSystemMetricsLoading = true;
  isNetworkMetricsLoading = true;
  isMemoryMetricsLoading = true;
  durationDifference = defaultMetricsDuration.value;
  chartsXLabels = [];
  formattedXaxis = [];
  metricsDuration = defaultMetricsDuration;
  fetchingCpuMetrics = true;
  fetchingDiskMetrics = true;
  fetchingFileSystemMetrics = true;
  fetchingSystemLoadMetrics = true;
  fetchingNetworkIOMetrics = true;
  fetchingMemoryMetrics = true;
  uniformLabels = [];

  constructor() {
    this.reset();
    makeObservable(this, {
      setLoading: action,
      isLoading: observable,
      setMetrics: action,
      metrics: observable,
      setMetricsAggregrateFunction: action,
      setMetricsDurationObject: action,
      setCPUMetrics: action,
      setNetworkMetrics: action,
      setDiskMetrics: action,
      setMachineId: action,
      machineId: observable,

      // Metric values
      cpuMetrics: observable,
      diskMetrics: observable,
      fileMetrics: observable,
      systemLoadMetrics: observable,
      networkMetrics: observable,
      memoryMetrics: observable,

      // Metric value setters
      setCpuMetricsValue: action,
      setDiskMetricsValue: action,
      setFileMetricsValue: action,
      setSystemLoadMetricsValue: action,
      setNetworkMetricsValue: action,
      setMemoryMetricsValue: action,

      // Metric value getters
      getCpuMetricsFromServer: action,
      getDiskMetricsFromServer: action,
      getFileMetricsFromServer: action,
      getSystemLoadMetricsFromServer: action,
      getNetworkMetricsFromServer: action,
      getMemoryMetricsFromServer: action,

      // Metrics loading states
      fetchingCpuMetrics: observable,
      fetchingDiskMetrics: observable,
      fetchingFileSystemMetrics: observable,
      fetchingSystemLoadMetrics: observable,
      fetchingNetworkIOMetrics: observable,
      fetchingMemoryMetrics: observable,

      fetchAllMetrics: action,
      clearAllMetrics: action,

      durationDifference: observable,
      chartsXLabels: observable,
      formattedXaxis: observable,
      metricsDuration: observable,
      uniformLabels: observable,
      setMetricsDuration: action,
      generateLabelsArray: action,
    });
  }

  reset = () => {
    Object.keys(initialValues).forEach(key => {
      this[key] = initialValues[key];
    });
  };

  log = new Logger({
    component: "machineMetricsStore",
    module: "Monitoring",
  });

  setLoading(value: boolean) {
    this.isLoading = value;
  }

  setMetricsAggregrateFunction = (value: string) => {
    this.metrics.aggregateFunc = value;
  };

  clearAllMetrics = () => {
    this.setCpuMetricsValue([]);
    this.setDiskMetricsValue([]);
    this.setFileMetricsValue([]);
    this.setSystemLoadMetricsValue([]);
    this.setMemoryMetricsValue([]);
    this.setNetworkMetricsValue([]);
  };

  generateLabelsArray = (
    timestampsArr: Map<number, any>,
    labelFormat: string,
  ) => {
    let keysArray = Array.from(timestampsArr.keys());

    const format = labelFormat;

    let formatedArr = [];
    keysArray.map(stamp => {
      formatedArr.push(
        dayjs((stamp as unknown as number) * 1000).format(format),
      );
    });
    formatedArr[0] = "";

    this.formattedXaxis = formatedArr;
  };
  
  setMetricsDurationObject = (durationObj, alias) => {
    const {
      value,
      interval,
      intervalValue,
      labelFormat,
      intervalMinutes,
    } = durationObj;
  
    this.clearAllMetrics();
    this.setMetricsDuration(durationObj);
  
    const endTimeStamp = dayjs().set("second", 0).unix();
    let dateFrom;
  
    if (intervalMinutes <= 60) {
      dateFrom = dayjs().set("second", 0).subtract(value, "minute").unix();
    } else if (intervalMinutes <= 120) {
      dateFrom = dayjs().set("minute", 0).set("second", 0).subtract(value, "minute").unix();
    } else {
      dateFrom = dayjs().set("hour", 0).set("minute", 0).subtract(value, "minute").unix();
    }
  
    const newStamps = generateUniformTimeStamps(dateFrom, endTimeStamp, intervalMinutes, labelFormat);
    this.uniformLabels = newStamps;
  
    const chartPlots = generateTimestampArray(dateFrom, endTimeStamp, intervalValue);
    this.generateLabelsArray(chartPlots, labelFormat);
  
    this.log.debug({ dateFrom }, "(setMetricsDurationObject) : datefrom");
  
    this.setChartXLabels(chartPlots);
  
    this.interval = interval;
    this.metrics.start = dateFrom;
    this.durationDifference = value;
  
    this.fetchAllMetrics(alias);
  };

  setMetricsDuration = (value: IMetricsDuration) => {
    this.metricsDuration = value;
  };

  setChartXLabels = value => {
    this.chartsXLabels = [...value.entries()];
  };

  setCPUMetrics = (value: IVisualizationFieldsObject) => {
    this.metrics.visualization.cpu = value;
  };

  setNetworkMetrics = (value: IVisualizationFieldsObject) => {
    this.metrics.visualization.network = value;
  };

  setDiskMetrics = (value: IVisualizationFieldsObject) => {
    this.metrics.visualization.disk = value;
  };

  setFileMetrics = (value: IVisualizationFieldsObject) => {
    this.metrics.visualization.file = value;
  };

  setMemoryMetrics = (value: IVisualizationFieldsObject) => {
    this.metrics.visualization.memory = value;
  };

  setLoadMetrics = (value: IVisualizationFieldsObject) => {
    this.metrics.visualization.load = value;
  };

  setMetrics = values => {
    this.metrics = values;
  };

  setMachineId = (id: string) => {
    this.machineId = id;
  };

  // Metric value setters
  setCpuMetricsValue = (response: MetricsDataType) => {
    this.cpuMetrics = response;
  };

  setDiskMetricsValue = (response: MetricsDataType) => {
    this.diskMetrics = response || [];
  };

  setFileMetricsValue = (response: MetricsDataType) => {
    this.fileMetrics = response || [];
  };

  setSystemLoadMetricsValue = (response: MetricsDataType) => {
    this.systemLoadMetrics = response || [];
  };

  setNetworkMetricsValue = (response: MetricsDataType) => {
    this.networkMetrics = response || [];
  };

  setMemoryMetricsValue = (response: MetricsDataType) => {
    this.memoryMetrics = response || [];
  };

  getCpuMetricsFromServer = async (machineId: string) => {
    if (machineId) {
      this.fetchingCpuMetrics = true;
      try {
        this.log.debug(
          this.metrics.start,
          "(getCpuMetricsFromServer) : metricsStart",
        );
        const requestBody = {
          metricsLabel: MetricsTypes.CPU_UTILIZATION,
          machineIdOrAlias: `${machineId}`,
          timeInterval: `${this.interval}`,
          start: this.metrics.start,
          end: dayjs().unix(),
        };
        const response = await getMachineMetrics(requestBody).catch(error => {
          ErrorReporter.collect(error, "Error while fetching CPU metrics");
          this.setLoading(false);
          throw error;
        });
        if (!response) throw new Error("Data Not found");

        this.setCPUMetrics({
          type: "cpu",
          timeInterval: requestBody.timeInterval,
          aggregateFunction: this.metrics.aggregateFunc,
          allowedIntervals: [],
        });

        this.setCpuMetricsValue(response?.payload?.data?.result || []);
        this.setLoading(false);
        return response;
      } catch (err) {
        throw err;
      } finally {
        this.fetchingCpuMetrics = false;
      }
    }
  };

  getNetworkMetricsFromServer = async (machineId: string) => {
    if (machineId) {
      this.fetchingNetworkIOMetrics = true;
      try {
        const requestBody = {
          metricsLabel: MetricsTypes.NETWORK_UTILIZATION,
          machineIdOrAlias: `${machineId}`,
          timeInterval: `${this.interval}`,
          start: this.metrics.start,
          end: dayjs().unix(),
        };
        const response = await getMachineMetrics(requestBody).catch(error => {
          ErrorReporter.collect(error, "Error while fetching Network metrics");
          this.setLoading(false);
          throw error;
        });
        if (!response) throw new Error("Data Not found");

        this.setNetworkMetrics({
          type: "network",
          timeInterval: requestBody.timeInterval,
          aggregateFunction: this.metrics.aggregateFunc,
          allowedIntervals: [],
        });

        this.setNetworkMetricsValue(response?.payload?.data?.result || []);
        this.setLoading(false);
        return response;
      } catch (err) {
        throw err;
      } finally {
        this.fetchingNetworkIOMetrics = false;
      }
    }
  };

  getDiskMetricsFromServer = async (machineId: string) => {
    if (machineId) {
      this.fetchingDiskMetrics = true;
      try {
        const requestBody = {
          metricsLabel: MetricsTypes.DISK_UTILIZATION,
          machineIdOrAlias: `${machineId}`,
          timeInterval: `${this.interval}`,
          start: this.metrics.start,
          end: dayjs().unix(),
        };
        const response = await getMachineMetrics(requestBody).catch(error => {
          ErrorReporter.collect(error, "Error while fetching Disk metrics");
          this.setLoading(false);
          throw error;
        });
        if (!response) throw new Error("Data Not found");

        this.setDiskMetrics({
          type: "disk",
          timeInterval: requestBody.timeInterval,
          aggregateFunction: this.metrics.aggregateFunc,
          allowedIntervals: [],
        });

        this.setDiskMetricsValue(response?.payload?.data?.result || []);
        this.setLoading(false);
        return response;
      } catch (err) {
        throw err;
      } finally {
        this.fetchingDiskMetrics = false;
      }
    }
  };

  getFileMetricsFromServer = async (machineId: string) => {
    if (machineId) {
      this.fetchingFileSystemMetrics = true;
      try {
        const requestBody = {
          metricsLabel: MetricsTypes.FILE_SYSTEM_UTILIZATION,
          machineIdOrAlias: `${machineId}`,
          timeInterval: `${this.interval}`,
          start: this.metrics.start,
          end: dayjs().unix(),
        };
        const response = await getMachineMetrics(requestBody).catch(error => {
          ErrorReporter.collect(error, "Error while fetching File metrics");
          this.setLoading(false);
          throw error;
        });
        if (!response) throw new Error("Data Not found");

        this.setFileMetrics({
          type: "file",
          timeInterval: requestBody.timeInterval,
          aggregateFunction: this.metrics.aggregateFunc,
          allowedIntervals: [],
        });

        this.setFileMetricsValue(response?.payload?.data?.result || []);
        this.setLoading(false);
        return response;
      } catch (err) {
        throw err;
      } finally {
        this.fetchingFileSystemMetrics = false;
      }
    }
  };

  getMemoryMetricsFromServer = async (machineId: string) => {
    if (machineId) {
      this.fetchingMemoryMetrics = true;
      try {
        const requestBody = {
          metricsLabel: MetricsTypes.MEMORY_UTILIZATION,
          machineIdOrAlias: `${machineId}`,
          timeInterval: `${this.interval}`,
          start: this.metrics.start,
          end: dayjs().unix(),
        };
        const response = await getMachineMetrics(requestBody).catch(error => {
          ErrorReporter.collect(error, "Error while fetching Memory metrics");
          this.setLoading(false);
          throw error;
        });
        if (!response) throw new Error("Data Not found");

        this.setFileMetrics({
          type: "memory",
          timeInterval: requestBody.timeInterval,
          aggregateFunction: this.metrics.aggregateFunc,
          allowedIntervals: [],
        });

        this.setMemoryMetricsValue(response?.payload?.data?.result || []);
        this.setLoading(false);
        return response;
      } catch (err) {
        throw err;
      } finally {
        this.fetchingMemoryMetrics = false;
      }
    }
  };

  getSystemLoadMetricsFromServer = async (machineId: string) => {
    if (machineId) {
      this.fetchingSystemLoadMetrics = true;
      try {
        const requestBody = {
          metricsLabel: MetricsTypes.SYSTEM_LOAD_UTILIZATION,
          machineIdOrAlias: `${machineId}`,
          timeInterval: `${this.interval}`,
          start: this.metrics.start,
          end: dayjs().unix(),
        };
        const response = await getMachineMetrics(requestBody).catch(error => {
          ErrorReporter.collect(error, "Error while fetching Load metrics");
          this.setLoading(false);
          throw error;
        });
        if (!response) throw new Error("Data Not found");

        this.setLoadMetrics({
          type: "load",
          timeInterval: requestBody.timeInterval,
          aggregateFunction: this.metrics.aggregateFunc,
          allowedIntervals: [],
        });

        this.setSystemLoadMetricsValue(response?.payload?.data?.result || []);
        this.setLoading(false);
        return response;
      } catch (err) {
        throw err;
      } finally {
        this.fetchingSystemLoadMetrics = false;
      }
    }
  };

  fetchAllMetrics = (machineId: string) => {
    this.getCpuMetricsFromServer(machineId);
    this.getDiskMetricsFromServer(machineId);
    this.getFileMetricsFromServer(machineId);
    this.getSystemLoadMetricsFromServer(machineId);
    this.getNetworkMetricsFromServer(machineId);
    this.getMemoryMetricsFromServer(machineId);
  };
}

export default new MetricesStore();
