import axios, { AxiosRequestConfig } from "axios";
import { API_BASE_URL } from "../config/envConstants";
import { isWindowUndefined } from "../utils/isWindowUndefined";
const API_URL_SUFFIX = "/api/";

export class CloudApiClient {

  static async handleError(err: any): Promise<void> {
    if (err.response && err.response.status === 401) {
      if (!isWindowUndefined(window)) {
        window.location.href = "/auth/signin";
      }
    }
  }

  static async httpGet(url: string): Promise<any> {
    try {
      const method = "GET";
      const options: AxiosRequestConfig = {
        method,
        url: url,
        withCredentials: true,
      };
      const res = await axios(options);
      return res.data;
    } catch (err) {
      await CloudApiClient.handleError(err);
      throw err?.response?.data || {};
    }
  }

  // CHECK ERRORS

  static httpGetWithErrorData = async (url: string): Promise<any> => {
    try {
      const method = "GET";
      const options: AxiosRequestConfig = {
        method,
        url: url,
        withCredentials: true,
      };
      const res = await axios(options);
      return res.data;
    } catch (err) {
      await CloudApiClient.handleError(err);
      throw err;
    }
  };


  static httpPost = async <T>(
    url: string,
    data: T extends unknown ? unknown : Record<string, unknown>,
  ): Promise<any> => {
    try {
      const method = "POST";
      const headers = {
        "Content-Type": "application/json",
      };
      const options: AxiosRequestConfig = {
        headers,
        method,
        url: url,
        onUploadProgress: p => {
          console.log(p);
        },
        data,
        withCredentials: true,
      };
      const res = await axios(options);
      return res.data;
    } catch (err: any) {
      await CloudApiClient.handleError(err);
      throw err?.response?.data || {};
    }
  };

  static httpPut = async (url: string, payload: unknown): Promise<any> => {
    try {
      const method = "PUT";
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      };
      const data = JSON.stringify(payload);
      const options: AxiosRequestConfig = {
        headers,
        method,
        url: url,
        data,
        withCredentials: true,
      };
      const res = await axios(options);
      return res.data;
    } catch (err) {
      await CloudApiClient.handleError(err);
      throw err?.response?.data || {};
    }
  };

  static httpPatch = async (
    url: string,
    payload: Record<string, unknown>,
  ): Promise<any> => {
    try {
      const method = "PATCH";
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      };
      const data = JSON.stringify(payload);
      const options: AxiosRequestConfig = {
        headers,
        method,
        url: url,
        data,
        withCredentials: true,
      };
      const res = await axios(options);
      return res.data;
    } catch (err) {
      await CloudApiClient.handleError(err);
      throw err?.response?.data || {};
    }
  };

  static httpDelete = async (url: string): Promise<any> => {
    try {
      const headers = {};
      const method = "DELETE";
      const options: AxiosRequestConfig = {
        headers,
        method,
        url: url,
        withCredentials: true,
      };
      const res = await axios(options);
      return res.data;
    } catch (err) {
      await CloudApiClient.handleError(err);
      throw err?.response?.data || {};
    }
  };

  static async uploadImage(formData: unknown): Promise<any> {
    try {
      const URL = `${API_BASE_URL + API_URL_SUFFIX}Fileupload`;
      const res = await axios.post(URL, formData, {
        // receive two parameter endpoint url ,form data
      });
      return res.data;
    } catch (err) {
      await CloudApiClient.handleError(err);
      throw err?.response?.data || {};
    }
  }
}
