import { action, makeObservable, observable } from "mobx";
import { ErrorReporter } from "../../libs/ErrorReporter";
import {
  getAutomationDetails,
  getAutomationVersions,
  getAutomations,
  getSystemAutomations,
} from "../../api/automationApis";
import { IUserAutomation, IAutomationInfo, IAutomationVersions, ISystemAutomation } from "../../interfaces/automationInterfaces";

type IAuthStoreFields = {
  isLoading: boolean;
  userAutomations: Partial<IUserAutomation[]>;
  systemAutomations: Partial<ISystemAutomation[]>;
  automationDetails: Partial<IAutomationInfo>;
  automationVersions: Partial<IAutomationVersions[]>;
};

const initialValues: IAuthStoreFields = {
  isLoading: true,
  userAutomations: [],
  systemAutomations: [],
  automationDetails: {},
  automationVersions: [],
};

class AutomationStore {
  isLoading: boolean;
  userAutomations: Partial<IUserAutomation[]>;
  systemAutomations: Partial<ISystemAutomation[]>;
  automationDetails: Partial<IAutomationInfo>;
  automationVersions: Partial<IAutomationVersions[]>;

  constructor() {
    this.reset();
    // makeAutoObservable(this, {}, { autoBind: true });
    makeObservable(this, {
      isLoading: observable,
      userAutomations: observable,
      systemAutomations: observable,
      automationDetails: observable,
      automationVersions: observable,
      setLoading: action,
      setUserAutomations: action,
      setSystemAutomations: action,
      setAutomationsDetails: action,
      setAutomationVersions: action,
      fetchUserAutomations: action,
      fetchSystemAutomations: action,
      fetchAutomationDetails: action,
      fetchAutomationVersions: action,
      getAutomations: action,
    });
  }

  reset = () => {
    Object.keys(initialValues).forEach(key => {
      this[key] = initialValues[key];
    });
  };

  setLoading(value: boolean) {
    this.isLoading = value;
  }

  setUserAutomations(value: IUserAutomation[]) {
    this.userAutomations = value;
  }

  setSystemAutomations(value: ISystemAutomation[]) {
    this.systemAutomations = value;
  }

  setAutomationsDetails(value: IAutomationInfo) {
    this.automationDetails = value;
  }

  setAutomationVersions(value: IAutomationVersions[]) {
    this.automationVersions = value;
  }

  fetchUserAutomations = async () => {
    try {
      const res = await getAutomations("user");
      if (res?.success) {
        const automationData = res.payload.automation;
        this.setUserAutomations(automationData);
      }
    } catch (err) {
      ErrorReporter.collect(err, "Error while fetching system automations");
    }
  };

  fetchSystemAutomations = async () => {
    try {
      const res = await getSystemAutomations();
      if (res?.success) {
        const automationData = res.payload;
        this.setSystemAutomations(automationData);
      }
    } catch (err) {
      ErrorReporter.collect(err, "Error while fetching system automations");
    }
  };

  fetchAutomationDetails = async (automationId: string) => {
    try {
      const res = await getAutomationDetails(automationId);
      if (res?.success) {
        const automationDetails = res.payload;
        this.setAutomationsDetails(automationDetails);
      }
    } catch (err) {
      ErrorReporter.collect(err, "Error while fetching automation details");
    }
  };

  fetchAutomationVersions = async (automationId: string) => {
    try {
      const res = await getAutomationVersions(automationId);
      if (res?.success) {
        const automationVersions = res.payload;
        this.setAutomationVersions(automationVersions);
      }
    } catch (err) {
      ErrorReporter.collect(err, "Error while fetching automation versions");
    }
  };

  getAutomations = async () => {
    await this.fetchUserAutomations();
    await this.fetchSystemAutomations();
  };

  initialize = async () => {
    try {
      await this.getAutomations();
    } catch (error) {
      ErrorReporter.collect(error, "Error while fetching automation data");
      throw error;
    }
  };
}

export default new AutomationStore();
