import { API_BASE_URL } from "../../config/envConstants";
import { CloudApiClient } from "../cloudApiClient";
import { IApiKeyGenerateKey } from "../../interfaces/apiKeysInterfaces";

const API_VERSION = "v1";
const API_URL_SUFFIX = "api";
const API_KEY_API_PREFIX = "api-keys";
const ACCOUNTS_PREFIX = "accounts";
const API_URL_WITHOUT_PREFIX = `${API_BASE_URL}/${API_URL_SUFFIX}/${API_VERSION}`;
const API_URL = `${API_URL_WITHOUT_PREFIX}/${ACCOUNTS_PREFIX}/${API_KEY_API_PREFIX}`;

export const generateApiKey = (body: IApiKeyGenerateKey) => {
  return CloudApiClient.httpPost(`${API_URL}/create`, {
    ...body,
  });
};

export const getApiKeys = () => {
  return CloudApiClient.httpGet(`${API_URL}`);
};

export const deleteApiKeys = (id: number) => {
  return CloudApiClient.httpDelete(`${API_URL}/${id}`);
};
