import { Roarr, ROARR } from "roarr";
import { IlogContextTypes } from "../interfaces/logInterfaces";
import { loggingEnabled } from "../config/envConstants";

class Logger {
  private logger;
  private enabled: boolean;

  constructor(context: IlogContextTypes, enabled: boolean = true) {
    this.logger = Roarr.child({
      ...context,
    });
    this.enabled = loggingEnabled;
  }

  debug(data, message) {
    if (this.enabled) {
      Roarr.debug(data, message);
    }
  }

  info(data, message) {
    if (this.enabled) {
      this.logger.info(data, message);
    }
  }

  warn(data, message) {
    if (this.enabled) {
      this.logger.warn(data, message);
    }
  }

  error(data, message) {
    if (this.enabled) {
      this.logger.error(data, message);
    }
  }

  trace(data, message) {
    if (this.enabled) {
      this.logger.trace(data, message);
    }
  }

  public static createLogs = () => {
    ROARR.write = message => {
      console.log(JSON.parse(message));
    };
  };

  // Method to enable or disable the logger
  setEnabled(enabled: boolean) {
    this.enabled = enabled;
  }
}

export default Logger;
