import dayjs from "dayjs";
import { getCurrentTheme } from "../utils/getCurrentTheme";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { metricsDataConstants } from "../components/Machines/MachineDetails/MachineTabs/MetricsTab/chartConfig/chartConfiguration";

dayjs.extend(advancedFormat);

import { Address4 } from "ip-address";
import {
  MECHA_GITHUB_PROFILE,
  MECHA_DISCORD_PROFILE,
  MECHA_FORUMS,
  MECHA_REDDIT_PROFILE,
  MECHA_DOCS_URL,
  MECHA_WEBSITE_LINK,
  MECHA_PRIVACY_DOCS,
  MECHA_TERMS_DOCS,
} from "./linkConstants";

export const CURRENT_UNIX = dayjs().unix();

export const LOGIN_URL = "/auth/signin";
export const UNAUTHORIZED_STATUS = 401;
export const FORBIDDEN_STATUS = 403;
export const MAX_PINNED_MACHINE = 6;
export const FETCH_PINNED_MACHINES = "pinned";
export const FETCH_UNPINNED_MACHINES = "unPinned";

export const VALID_USERNAME_REGEX =
  /^(?!-)(?!.*--)[a-z0-9](?:[a-zA-Z0-9-]{0,48}[a-zA-Z0-9])?$/;

export const DETAILED_DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

export const UNIX_DATE_FORMAT = "X";

export const MACHINE_TABS = {
  APPS: {
    value: "APPS",
    text: "Apps",
  },
  ACTIVITY: {
    value: "ACTIVITY",
    text: "Activity",
  },
  SETTINGS: {
    value: "SETTINGS",
    text: "Settings",
  },
};

export const defaultMachineIcons = [
  {
    id: 0,
    src: "/assets/img/machines/machine-image1.png",
    name: "mecha-m",
    isDefault: false,
  },
  {
    id: 1,
    src: "/assets/img/machines/machine-image2.png",
    name: "mecha-qube",
    isDefault: false,
  },
  {
    id: 2,
    src: "/assets/img/machines/machine-image3.png",
    name: "mecha-hexagone",
    isDefault: false,
  },
  {
    id: 3,
    src: "/assets/img/machines/machine-image4.png",
    name: "mecha-plus",
    isDefault: false,
  },
  {
    id: 4,
    src: "/assets/img/machines/machine-image5.png",
    name: "mecha-coin",
    isDefault: false,
  },
];

export const defaultMachineImages = [
  ...defaultMachineIcons,
  {
    id: 5,
    src: "/assets/img/machines/mecha-compute.png",
    name: "mecha-compute",
    isDefault: true,
  },
  {
    id: 6,
    src: "/assets/img/machines/raspberry-pi.png",
    name: "raspberry-pi",
    isDefault: false,
  },
  {
    id: 7,
    src: "/assets/img/machines/generic-machine.png",
    name: "generic-machine",
    isDefault: false,
  },
  {
    id: 8,
    src: "/assets/img/machines/mecha-desktop.png",
    name: "mecha-desktop",
    isDefault: false,
  },
  {
    id: 9,
    src: "/assets/img/machines/mecha-m.png",
    name: "default-mecha-m",
    isDefault: false,
  },
];

export const defaultAvatars = [
  {
    id: 0,
    src: "/assets/avatars/Avatar-1.png",
    name: "avatar-1",
  },
  {
    id: 1,
    src: "/assets/avatars/Avatar-2.png",
    name: "avatar-2",
  },
  {
    id: 2,
    src: "/assets/avatars/Avatar-3.png",
    name: "avatar-3",
  },
  {
    id: 3,
    src: "/assets/avatars/Avatar-4.png",
    name: "avatar-4",
  },
  {
    id: 4,
    src: "/assets/avatars/Avatar-5.png",
    name: "avatar-5",
  },
  {
    id: 5,
    src: "/assets/avatars/Avatar-6.png",
    name: "avatar-6",
  },
  {
    id: 6,
    src: "/assets/avatars/Avatar-7.png",
    name: "avatar-7",
  },
  {
    id: 7,
    src: "/assets/avatars/Avatar-8.png",
    name: "avatar-8",
  },
];

export const trophyData = [
  {
    id: 0,
    src: "/assets/trophys/trophy-1.png",
  },
  {
    id: 1,
    src: "/assets/trophys/trophy-2.png",
  },
  {
    id: 2,
    src: "/assets/trophys/trophy-3.png",
  },
  {
    id: 3,
    src: "/assets/trophys/trophy-4.png",
  },
  {
    id: 4,
    src: "/assets/trophys/trophy-5.png",
  },
  {
    id: 5,
    src: "/assets/trophys/trophy-6.png",
  },
];

export const metricsIntervalDurations = [
  { label: "60s", value: 60 * 1000 },
  { label: "1h", value: 60 * 60 * 1000 },
  { label: "1d", value: 60 * 60 * 24 * 1000 },
];

export const durations = [
  {
    label: "Last 5 minutes",
    value: "5",
    durationDifference: 5,
    interval: "60s",
    from: dayjs().subtract(5, "minute").unix(),
    to: CURRENT_UNIX,
    intervalValue: 60,
    labelFormat: "HH:mm",
    labelGap: 1,
    intervalMinutes: 1 // 1 minute
  },
  {
    label: "Last 15 minutes",
    value: "15",
    durationDifference: 15,
    interval: "60s",
    from: dayjs().subtract(15, "minute").unix(),
    to: CURRENT_UNIX,
    intervalValue: 60,
    labelFormat: "HH:mm",
    labelGap: 3,
    intervalMinutes: 5 // 5 minutes
  },
  {
    label: "Last 30 minutes",
    value: "30",
    durationDifference: 30,
    interval: "60s",
    from: dayjs().subtract(30, "minute").unix(),
    to: CURRENT_UNIX,
    intervalValue: 60,
    labelFormat: "HH:mm",
    labelGap: 6,
    intervalMinutes: 15 // 15 minutes
  },
  {
    label: "Last 1 hour",
    value: "60",
    durationDifference: 60,
    interval: "60s",
    from: dayjs().subtract(60, "minute").unix(),
    to: CURRENT_UNIX,
    intervalValue: 60,
    labelFormat: "HH:mm",
    labelGap: 2,
    intervalMinutes: 30 // 30 minutes
  },
  {
    label: "Last 3 hours",
    value: "180",
    durationDifference: 180,
    interval: "60s",
    from: dayjs().subtract(180, "minute").unix(),
    to: CURRENT_UNIX,
    intervalValue: 60,
    labelFormat: "HH:mm",
    labelGap: 6,
    intervalMinutes: 60 // 1 hour
  },
  {
    label: "Last 6 hours",
    value: "360",
    durationDifference: 360,
    interval: "5m",
    from: dayjs().subtract(360, "minute").unix(),
    to: CURRENT_UNIX,
    intervalValue: 60 * 5,
    labelFormat: "HH:mm",
    labelGap: 12,
    intervalMinutes: 120 // 2 hours
  },
  {
    label: "Last 12 hours",
    value: "720",
    durationDifference: 720,
    interval: "5m",
    from: dayjs().subtract(720, "minute").unix(),
    to: CURRENT_UNIX,
    intervalValue: 60 * 5,
    labelFormat: "HH:mm",
    labelGap: 24,
    intervalMinutes: 360 // 6 hours
  },
  {
    label: "Last 24 hours",
    value: "1440",
    durationDifference: 1440,
    interval: "1h",
    from: dayjs().subtract(1440, "minute").unix(),
    to: CURRENT_UNIX,
    intervalValue: 60 * 60,
    labelFormat: "HH:mm",
    labelGap: 24,
    intervalMinutes: 360 // 6 hours
  },
  {
    label: "Last 7 days",
    value: "10080",
    durationDifference: 10080,
    interval: "1h",
    from: dayjs().subtract(10080, "minute").unix(),
    to: CURRENT_UNIX,
    intervalValue: 60 * 60,
    labelFormat: "D MMM HH:mm",
    labelGap: 24,
    intervalMinutes: 2880 // 2 day
  },
];

export const logLevels = [
  {
    value: "All",
    key: "all",
  },
  {
    value: "Debug",
    key: "debug",
  },
  {
    value: "Error",
    key: "error",
  },
  {
    value: "Info",
    key: "info",
  },
  {
    value: "Warn",
    key: "warn",
  },
];

export const aggregrateArray = ["SUM", "AVG", "COUNT", "MAX"];

export const verticalGridInterval = 60 * 60 * 12 * 1000; //12 Hours Vertical Grid

export const logLimits = [100, 1000];

export const pageSizes = [10, 20, 30, 40, 50, 60, 70, 80, 90];

export enum PageTitles {
  VERIFY_EMAIL = "Verify your email address - Mecha",
  SIGN_IN = "Sign in - Mecha",
  SIGN_UP = "Sign up - Mecha",
  FORGOT_PASSWORD = "Forgot password - Mecha",
  TWO_FACTOR_AUTHENTICATION = "2FA - Mecha",
  RESET_PASSWORD = "Reset password - Mecha",
  CONFIRM_ACCESS = "Confirm access - Mecha",
  EMAIL_VERIFICATION_SUCCESS = "Your email is verified - Mecha",
}

export const metricsData = [
  metricsDataConstants.CPU,
  metricsDataConstants.DISK,
  metricsDataConstants.FILES,
  metricsDataConstants.LOAD,
  metricsDataConstants.NETWORK,
  metricsDataConstants.MEMORY,
];

export const MultiSelectColourStyles = () => {
  const currTheme = getCurrentTheme();
  return {
    input: styles => {
      return {
        ...styles,
        color: currTheme === "dark" ? "#FFFFFF" : "#15171D",
        borderRadius: "5px",
      };
    },
    multiValue: styles => {
      return {
        ...styles,
        background: currTheme === "dark" ? "#18212F" : "#EFF4F9",
        borderRadius: "5px",
        padding: "6px 5px",
      };
    },
    multiValueLabel: styles => ({
      ...styles,
      color: currTheme === "dark" ? "#ADB8CD" : "#646D8A",
      background: currTheme === "dark" ? "#18212F" : "#EFF4F9",
    }),
    multiValueRemove: styles => ({
      ...styles,
      color: currTheme === "dark" ? "#ADB8CD" : "#646D8A",
      background: currTheme === "dark" ? "#18212F" : "#EFF4F9",
      cursor: "pointer",
      borderRadius: "0",
      ":hover": {
        background: currTheme === "dark" ? "#18212F" : "#EFF4F9",
        color: currTheme === "dark" ? "#ADB8CD" : "#646D8A",
      },
    }),
    menuList: styles => ({
      ...styles,
      color: currTheme === "dark" ? "#ADB8CD" : "#646D8A",
      background: currTheme === "dark" ? "#18212F" : "#FDFDFD",
      cursor: "pointer",
      borderRadius: "0",
      padding: 0,
      ":hover": {
        background: currTheme === "dark" ? "#18212F" : "#EFF4F9",
        color: currTheme === "dark" ? "#ADB8CD" : "#646D8A",
      },
    }),
    option: styles => ({
      ...styles,
      color: currTheme === "dark" ? "#ADB8CD" : "#646D8A",
      background: currTheme === "dark" ? "#18212F" : "#FDFDFD",
      cursor: "pointer",
      borderRadius: "0",
      ":hover": {
        background: currTheme === "dark" ? "#18212F" : "#EFF4F9",
        color: currTheme === "dark" ? "#FFFFFF" : "#15171D",
      },
    }),
  };
};

export enum cloudStatus {
  ENABLE_REQUESTED = 0,
  ENABLED = 10,
  DISABLE_REQUESTED = 20,
  DISABLED = 30,
}

export const MACHINE_IP_VERSION = 4;

// const chartYScale = (yCoordinateText, currTheme) => {
//   const title = {
//     display: true,
//     text: ` Value in ${yCoordinateText || ""}`,
//   };
//   const grid = {
//     display: true,
//     color:
//       currTheme === "dark" ? "rgba(255, 255, 255, 0.1)" : "rgba(0, 0, 0, 0.1)",
//   };

//   if (yCoordinateText) {
//     return {
//       title,
//       grid,
//     };
//   } else {
//     return {
//       grid,
//     };
//   }
// };

// export const chartLegend = legend => {
//   if (legend) {
//     return {
//       labels: {
//         usePointStyle: true,
//       },
//       position: "top" as const,
//       align: "end" as const,
//     };
//   } else {
//     return { display: false };
//   }
// };

// export const metricsColorScheme = [
//   "rgb(48, 127, 226)",
//   "rgb(153, 108, 250)",
//   "rgb(231, 142, 169)",
//   "rgb(255, 255, 255)",
//   "rgb(240, 194, 54)",
// ];

// export const chartOptions = (yCoordinateText?: string, legend?: boolean) => {
//   const currTheme = getCurrentTheme();
//   return {
//     type: "line",
//     responsive: true,
//     height: 200,
//     maintainAspectRatio: false,
//     plugins: {
//       legend: chartLegend(legend),
//       tooltip: {
//         enabled: false,
//         position: "nearest",
//         externalTooltipHandler,
//       },
//     },
//     interaction: {
//       intersect: false,
//       mode: "index",
//     },
//     scales: {
//       x: {
//         grid: {
//           display: true,
//           color:
//             currTheme === "dark"
//               ? "rgba(255, 255, 255, 0.1)"
//               : "rgba(0, 0, 0, 0.1)",
//         },
//       },
//       y: chartYScale(yCoordinateText, currTheme),
//     },
//   };
// };

export enum WebSocketEventCode {
  ENABLED_IP = "ENABLED_IP",
  ENABLED_DSN = "ENABLED_DSN",
  DISABLED_IP = "DISABLED_IP",
  DISABLED_DSN = "DISABLED_DSN",
  MACHINE_STATUS_UPDATE = "MACHINE_STATUS_UPDATE",
  MACHINE_PROVISIONING_COMPLETE = "MACHINE_PROVISIONING_COMPLETE",
}

export enum AppModule {
  MACHINE_DETAILS = "MACHINE_DETAILS",
  MACHINE_LISTING = "MACHINE_LISTING",
}

export enum FirewallDirection {
  INBOUND = "INBOUND",
  OUTBOUND = "OUTBOUND",
}

export const PASSWORD_STRENGTH_TYPE = {
  WEAK: "weak",
  MEDIUM: "medium",
  STRONG: "strong",
};

export const getColorBasedOnStrength = passwordStrength => {
  if (passwordStrength === PASSWORD_STRENGTH_TYPE.WEAK) {
    return "#E4002B";
  } else if (passwordStrength === PASSWORD_STRENGTH_TYPE.MEDIUM) {
    return "#FEDD00";
  } else if (passwordStrength === PASSWORD_STRENGTH_TYPE.STRONG) {
    return "#00AA13";
  } else {
    return "#848DA6";
  }
};

export const getWidthBasedOnStrength = passwordStrength => {
  if (passwordStrength === PASSWORD_STRENGTH_TYPE.WEAK) {
    return "w-1/3";
  } else if (passwordStrength === PASSWORD_STRENGTH_TYPE.MEDIUM) {
    return "w-2/3";
  } else if (passwordStrength === PASSWORD_STRENGTH_TYPE.STRONG) {
    return "w-full";
  } else {
    return "w-0";
  }
};

export const mechaEmailVerificationId = "mecha.email_verification.id";
export const isGithubLogin = "is_github_login";



export enum MetricsTypes {
  CPU_UTILIZATION = "SYSTEM_CPU_UTILIZATION",
  MEMORY_UTILIZATION = "SYSTEM_MEMORY_USAGE",
  SYSTEM_LOAD_UTILIZATION = "SYSTEM_CPU_LOAD_AVERAGE_15M",
  NETWORK_UTILIZATION = "SYSTEM_NETWORK_IO",
  DISK_UTILIZATION = "SYSTEM_DISK_IO",
  FILE_SYSTEM_UTILIZATION = "SYSTEM_FILESYSTEMS_USAGE",
}

export const footerLinks = [
  { title: "Home", redirectUrl: MECHA_WEBSITE_LINK },
  {
    title: "Forum",
    redirectUrl: `${MECHA_FORUMS}`,
  },
  { title: "Docs", redirectUrl: `${MECHA_DOCS_URL}` },
  { title: "Privacy", redirectUrl: `${MECHA_PRIVACY_DOCS}` },
  { title: "Terms", redirectUrl: `${MECHA_TERMS_DOCS}` },
];

export const captchaValidationFailMessage =
  "Captcha verification failed, please try again";

export const domainDurations = ["30", "60", "120", "240", "365"];

export const SocialLinks = {
  github: MECHA_GITHUB_PROFILE,
  discord: MECHA_DISCORD_PROFILE,
  forums: MECHA_FORUMS,
  reddit: MECHA_REDDIT_PROFILE,
};

export enum ENCRYPTION_STEPS {
  GENERATE_KEY = "GENERATE_KEY",
  KEY_DETAILS = "KEY_DETAILS",
}

export enum API_STATUS {
  "IDLE" = "IDLE",
  "PROCESSING" = "PROCESSING",
  "DONE" = "DONE",
}

export const customProtocols = [
  {
    label: "TCP",
    value: "TCP",
  },
  {
    label: "UDP",
    value: "UDP",
  },
  {
    label: "ICMP",
    value: "ICMP",
  },
  {
    label: "Any",
    value: "Any",
  },
];

export enum WarningType {
  WARNING = "WARNING",
  INFO = "INFO",
}

export enum ApiKeyModalSteps {
  STEP1 = 1,
  STEP2 = 2,
}

export enum ApiKeyStatus {
  NO_EXPIRY = "NO_EXPIRY",
  EXPIRED = "EXPIRED",
}

export const PRE_DEFINED_MACHINE_LIMIT = 20;

export const subnetSuggestions = [
  "172.16.36.0",
  "100.100.10.0",
  "10.10.10.0",
  "192.168.0.0",
];

export const privateNetworkRanges = [
  new Address4("172.16.0.0/12"),
  new Address4("100.64.0.0/10"),
  new Address4("10.0.0.0/8"),
  new Address4("192.168.0.0/16")
];
export const MECHA_SUBNET_CIDR = "24"

export const MECHA_APP_SERVICES_SUFFIX = ".app.mec.sh";

export const MECHA_APP_SERVICES_SUFFIX_REGEX = /(\.aap)?\.mec\.sh$/;

export const appServiceDnsNameRegex = /^[a-zA-Z](?!.*--)[a-zA-Z0-9-]*[a-zA-Z0-9]$/;

export enum AppServiceModals {
  ADD_SERVICE = "ADD_APP_SERVICE",
  EDIT_SERVICE = "EDIT_APP_SERVICE",
  DELETE_SERVICE = "DELETE_APP_SERVICE",
}