export const generateTimestampArray = (startValue, endValue, interval) => {
  let timestampMap = new Map();
  let currentTimestamp = startValue;

  while (currentTimestamp <= endValue) {
    timestampMap.set(currentTimestamp, null);
    currentTimestamp += interval; // Increment timestamp by the given interval (in seconds)
  }

  return timestampMap;
};

// Example   ---------------------*---------------------

// const startValue = 1712549180; // Monday, 8 April 2024 09:36:20
// const endValue = 1712559980;   // Monday, 8 April 2024 12:36:20
// const interval = 60;            // 60 seconds

// const timestampMap = generateTimestampArray(startValue, endValue, interval);
