import { customToast } from "./customToast";

export function showErrorToast(err) {
  let toastMsg = "Something went wrong, please try again";

  if (err && err?.errorCode) {
    switch (err.errorCode) {
      case ERROR_CODES.NOT_FOUND_IN_DB:
      case ERROR_CODES.INVALID_DATA_EXCEPTION:
      case ERROR_CODES.ACCOUNT_LIMIT_EXCEEDED:
      case ERROR_CODES.BAD_REQUEST:
      case ERROR_CODES.NOT_FOUND:
      case ERROR_CODES.UNAUTHORIZED:
      case ERROR_CODES.EMAIL_ALREADY_VERIFIED:
      case ERROR_CODES.UNPROCESSABLE_ENTITY:
        toastMsg = err?.message || toastMsg;
        break;
    }
  }

  customToast({
    msg: toastMsg,
    toastType: "ERROR",
  });
}

export enum ERROR_CODES {
  REQUIRED_FIELDS_MISSING = "1001",
  DATABASE_OPERATION_ERROR = "1002",
  NOT_FOUND_IN_DB = "1003",
  INVALID_DATA_EXCEPTION = "1004",
  ACCOUNT_LIMIT_EXCEEDED = "1005",
  NATS_OPERATION_ERROR = "1006",
  ORY_OPERATION_ERROR = "1007",
  NOTIFICATION_EMAIL_SENT_ERROR = "2900",
  INTERNAL_ERROR = "500",
  BAD_REQUEST = "400",
  NOT_FOUND = "404",
  UNAUTHORIZED = "401",
  EMAIL_ALREADY_VERIFIED = "2201",
  UNPROCESSABLE_ENTITY = "422",
}
