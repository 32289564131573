import * as Sentry from "@sentry/browser";

const exceptionTrackingDSN = process.env.NEXT_PUBLIC_SENTRY_DSN;

export function isExceptionTrackingRequired() {
  if (exceptionTrackingDSN && process.env.NEXT_PUBLIC_ENABLE_SENTRY === "1") {
    return true;
  }
  return false;
}

interface IBreadCrumb {
  category: string;
  message: string;
  level: Sentry.SeverityLevel;
  from: string;
  to: string;
}

export enum BREADCRUMB_LEVEL {
  FATAL = "fatal",
  CRITICAL = "critical",
  ERROR = "error",
  WARINING = "warning",
  LOG = "log",
  INFO = "info",
  DEBUG = "debug",
}

export class ErrorReporter {
  static collect(
    error: Error,
    message: string,
    data: { [key: string]: any } = {},
  ) {
    const isSSR = typeof window === "undefined";
    if (isExceptionTrackingRequired()) {
      const pathname = !isSSR ? window?.location?.pathname : "";
      Sentry.addBreadcrumb({
        data: {
          ...data,
          pathname,
        },
        message,
      });
      Sentry.captureException(error);
    }
  }

  static addBreadcrumb({ category, message, level, from, to }: IBreadCrumb) {
    if (isExceptionTrackingRequired()) {
      Sentry.addBreadcrumb({
        category,
        message,
        level,
        data: {
          from: from,
          to: to,
        },
      });
    }
  }

  static setUser(id: string) {
    if (isExceptionTrackingRequired()) {
      Sentry.setUser({ userId: id });
    }
  }
}
