import { action, makeObservable, observable } from "mobx";
import {
  addMachineProvisionApi,
  getMachineCertificate,
  getMachinesApi,
  getMachineTemplates,
  getPinnedMachinesApi,
  getPreviousCertificates,
  getSingleMachine,
  UpdateMachineInfoApi,
} from "../../api/machinesApis";
import {
  MAX_PINNED_MACHINE,
  PRE_DEFINED_MACHINE_LIMIT,
} from "../../config/constants";
import {
  MachineModalSteps,
  IMachineContent,
  IMachineProvisionReq,
  IMachineSearch,
  IMachineTemplates,
  IEventsData,
  ILogsData,
  IUpdateMachineReq,
} from "../../interfaces/machinesInterfaces";
import AuthStore from "./authStore";

import { ErrorReporter } from "../../libs/ErrorReporter";
import { showErrorToast } from "../../utils/errorsToast";
import { EventTracker } from "../../libs/EventTracker";
import { EventTrackingCodes } from "../../interfaces/eventsInterfaces";

export interface IMachineCertificate {
  id: number;
  machineId: string;
  privateKeyAlgo: string;
  privateKeySize: string;
  pubSignAlgo: string;
  certSerialNumber: string;
  certFingerprint: string;
  certPkiName: string;
  certCreatedAt: string;
  certExpiresAt: string;
  certCommonName: string;
  certIssuingUrl: string;
  certCrlUrl: string;
  issuingCaSerialNumber: string;
  issuingCaCommonName: string;
  issuingCaExpiresAt: string;
  rootCaCommonName: string;
  rootCaExpiresAt: string;
  createdAt: string;
  updatedAt: string;
  revoked: boolean;
}

type IMachineStoreFields = {
  isEventsPayloadModalOpen: boolean;
  eventsPayloadData: Partial<IEventsData>;
  isLogsPayloadModalOpen: boolean;
  logsPayloadData: Partial<ILogsData>;
  addMachineModal: boolean;
  addMachineModalLoading: boolean;
  modalCurrentStep: number;
  provisionMachineId: string;
  provisionRequestId: number;
  machinesList: IMachineContent[];
  fetchingMachines: boolean;
  totalRecords: number;
  offset: number;
  limit: number;
  sortBy: string;
  sortDirection: string;
  machineSearch: IMachineSearch;
  machineTemplates: IMachineTemplates[];
  currentMachineId: string;
  isMachineOnline: boolean;
  machineInfo: Partial<IMachineContent>;
  addMachineKind: string;
  machineDetailsPath: string;
  machineCertificate: Partial<IMachineCertificate>;
  previousCertificates: Partial<IMachineCertificate>[];
  fetchingMachineCertificate: boolean;
  fetchingPreviousCertificates: boolean;
  fetchingMachineData: boolean;
  machineSearchLoader: boolean;
};

const initialValues: IMachineStoreFields = {
  isEventsPayloadModalOpen: false,
  eventsPayloadData: {},
  isLogsPayloadModalOpen: false,
  logsPayloadData: {},
  addMachineModal: false,
  addMachineModalLoading: true,
  modalCurrentStep: MachineModalSteps.STEP1,
  machinesList: [],
  machineTemplates: [],
  fetchingMachines: true,
  totalRecords: 0,
  offset: 1,
  sortBy: "name",
  sortDirection: "ASC",
  limit: PRE_DEFINED_MACHINE_LIMIT,
  machineSearch: { tags: [], searchText: "" },
  currentMachineId: "",
  provisionMachineId: "",
  provisionRequestId: -1,
  isMachineOnline: false,
  machineInfo: {},
  addMachineKind: "",
  machineDetailsPath: "",
  machineCertificate: null,
  previousCertificates: [],
  fetchingMachineCertificate: true,
  fetchingPreviousCertificates: true,
  fetchingMachineData: true,
  machineSearchLoader: false,
};
class MachineStore {
  isEventsPayloadModalOpen: boolean;
  eventsPayloadData: Partial<IEventsData>;
  isLogsPayloadModalOpen: boolean;
  logsPayloadData: Partial<ILogsData>;
  addMachineModal: boolean;
  addMachineModalLoading: boolean;
  modalCurrentStep: number;
  machinesList: IMachineContent[];
  fetchingMachines: boolean;
  totalRecords: number;
  offset: number;
  limit: number;
  sortBy: string;
  sortDirection: string;
  machineSearch: IMachineSearch;
  machineSearchLoader: boolean;
  machineTemplates: IMachineTemplates[];
  currentMachineId: string;
  provisionMachineId: string;
  provisionRequestId: number;
  isMachineOnline: boolean;
  machineInfo: Partial<IMachineContent>;
  addMachineKind: string;
  machineDetailsPath: string;
  machineCertificate: Partial<IMachineCertificate>;
  previousCertificates: Partial<IMachineCertificate>[];
  fetchingMachineCertificate: boolean;
  fetchingPreviousCertificates: boolean;
  fetchingMachineData: boolean;

  constructor() {
    this.reset();
    makeObservable(this, {
      updateMachineById: action,
      getMachineById: action,
      getAllMachines: action,
      getBothMachines: action,
      getMachineTemplates: action,
      createMachineProvisioningRequest: action,
      handleAddModalClose: action,
      updateMachine: action,
      updateMachineInfo: action,
      setMachineSearch: action,
      setCurrentMachineId: action,
      setOffset: action,
      setLimit: action,
      setAddMachineModal: action,
      setAddMachineModalLoading: action,
      setModalCurrentStep: action,
      setEventsPayloadModalOpen: action,
      setEventsPayloadData: action,
      setLogsPayloadModalOpen: action,
      setLogsPayloadData: action,
      setFetchingMachines: action,
      setProvisionMachineId: action,
      setProvisionRequestId: action,
      setIsMachineOnline: action,
      setMachineInfo: action,
      isEventsPayloadModalOpen: observable,
      eventsPayloadData: observable,
      isLogsPayloadModalOpen: observable,
      logsPayloadData: observable,
      addMachineModal: observable,
      addMachineModalLoading: observable,
      modalCurrentStep: observable,
      machinesList: observable,
      fetchingMachines: observable,
      totalRecords: observable,
      offset: observable,
      limit: observable,
      sortBy: observable,
      setSortBy: action,
      sortDirection: observable,
      machineSearch: observable,
      machineTemplates: observable,
      currentMachineId: observable,
      provisionMachineId: observable,
      provisionRequestId: observable,
      isMachineOnline: observable,
      machineInfo: observable,
      addMachineKind: observable,
      setAddMachineKind: action,
      machineDetailsPath: observable,
      setMachineDetailsPath: action,
      machineCertificate: observable,
      previousCertificates: observable,
      fetchingMachineCertificate: observable,
      setFetchingMachineCertificate: action,
      fetchingPreviousCertificates: observable,
      fetchingMachineData: observable,
      setFetchingmachineData: action,
      machineSearchLoader: observable,
      setMachineCertificate: action,
    });
  }

  reset = () => {
    Object.keys(initialValues).forEach(key => {
      this[key] = initialValues[key];
    });
  };

  updateMachineById = async (
    body: IUpdateMachineReq,
    machineId = this.currentMachineId,
  ) => {
    try {
      const res = await UpdateMachineInfoApi(machineId, body);
      EventTracker.track(EventTrackingCodes.MACHINE_INFO, { body });
      this.getAllMachines();
      return res;
    } catch (error) {
      ErrorReporter.collect(error, "Error while updating machine by Id", {
        machineId: machineId,
      });
      throw error;
    }
  };

  setMachineData = async (machineId: string) => {
    try {
      await this.getMachineById(`${machineId}`);
      this.setCurrentMachineId(`${machineId}`);
      if (!this.machineCertificate) {
        await this.getMachineCertificate(`${machineId}`);
        await this.getPreviouslyGeneratedCertificates();
      }
    } catch (err) {
      throw err;
    }
  };

  getMachineById = async (machineId: string) => {
    if (machineId) {
      if (machineId !== this.currentMachineId) {
        this.fetchingMachineData = true;
      }
      try {
        const res = await getSingleMachine(machineId);
        this.setMachineInfo(res?.payload);
        return res;
      } catch (error) {
        ErrorReporter.collect(error, "Error while fetching machine by Id", {
          machineId: this.currentMachineId,
        });
        throw error;
      } finally {
        this.fetchingMachineData = false;
      }
    }
  };

  getAllMachines = async (showLoader = true) => {
    try {
      if (this.machinesList.length == 0 || showLoader) {
        this.fetchingMachines = true;
      }
      const res = await getMachinesApi(
        this.offset,
        this.limit,
        this.sortBy,
        this.sortDirection,
        this.machineSearch?.searchText,
        this.machineSearch?.tags,
      );
      if (res) {
        this.machinesList = res?.payload?.content || [];
        this.totalRecords = res?.payload?.totalRecordCount || 0;
        return res?.payload?.content || [];
      }
    } catch (error) {
      ErrorReporter.collect(error, "Error while fetching all machines", {
        userId: AuthStore.loggedInUser.email,
      });
    } finally {
      this.fetchingMachines = false;
      this.machineSearchLoader = false;
    }
  };

  getBothMachines = async () => {
    this.getAllMachines();
  };

  getMachineTemplates = async () => {
    try {
      const res = await getMachineTemplates();
      const parsedData = res?.payload?.map(item => ({
        ...item,
        setupInstructions: JSON.parse(
          JSON.parse(item.setupInstructions as string),
        ),
      }));
      this.machineTemplates = [...parsedData];
    } catch (error) {
      ErrorReporter.collect(error, "Error while fetching machine templates");
    }
  };

  createMachineProvisioningRequest = async (request: IMachineProvisionReq) => {
    try {
      const response = await addMachineProvisionApi(request);
      this.setProvisionMachineId(response.payload?.machineId);
      this.setProvisionRequestId(response.payload?.requestId);
      return response;
    } catch (error) {
      ErrorReporter.collect(error, "Error while addMachineProvisionApi ");
      return error;
    }
  };

  handleAddModalClose = () => {
    this.setAddMachineModal(false);
    this.setModalCurrentStep(MachineModalSteps.STEP1);
    this.setCurrentMachineId("");
    this.setMachineInfo({});
    this.setProvisionMachineId("");
    this.setProvisionRequestId(-1);
    this.getAllMachines();
  };

  setMachineCertificate = value => {
    this.machineCertificate = value;
  };

  getMachineCertificate = async (aliasOrId: string) => {
    this.setFetchingMachineCertificate(true);
    try {
      const certificate = await getMachineCertificate(aliasOrId);
      this.setMachineCertificate(certificate.payload);
    } catch (error) {
      showErrorToast(error);
      ErrorReporter.collect(error, "Error in fetching certificate");
    } finally {
      this.setFetchingMachineCertificate(false);
    }
  };

  getPreviouslyGeneratedCertificates = async () => {
    this.fetchingPreviousCertificates = true;
    try {
      const certificatesData = await getPreviousCertificates(
        this.currentMachineId,
      );
      this.previousCertificates = certificatesData.payload;
    } catch (error) {
      showErrorToast(error);
      ErrorReporter.collect(
        error,
        "Error in fetching previous generated certificate",
      );
    } finally {
      this.fetchingPreviousCertificates = false;
    }
  };

  setAddMachineKind = value => {
    this.addMachineKind = value;
  };

  setMachineList = data => {
    this.machinesList = data;
  };

  updateMachine = (machineId: string, toUpdate: Partial<IMachineContent>) => {
    const machineIndex: number = this.machinesList.findIndex(
      d => d.machineId === machineId,
    );
    if (machineIndex === -1) {
      return;
    }
    const machines = this.machinesList.slice();
    machines[machineIndex] = { ...machines[machineIndex], ...toUpdate };
    this.machinesList = machines;
  };

  updateMachineInfo = toUpdate => {
    const updatedData = { ...this.machineInfo, ...toUpdate };
    this.machineInfo = updatedData;
  };

  setMachineSearch = (data: Partial<IMachineSearch>) => {
    this.machineSearchLoader = true;
    this.machineSearch = Object.assign({}, this.machineSearch, data);
    this.getAllMachines(false);
  };

  setCurrentMachineId = (id: string) => {
    this.currentMachineId = id;
  };

  setOffset = (offset: number) => {
    this.offset = offset;
  };

  setLimit = (limit: number) => {
    this.limit = limit;
  };

  setAddMachineModal = (value: boolean) => {
    this.addMachineModal = value;
  };

  setAddMachineModalLoading = (value: boolean) => {
    this.addMachineModalLoading = value;
  };

  setModalCurrentStep = (step: number) => {
    this.modalCurrentStep = step;
  };

  setEventsPayloadModalOpen = (value: boolean) => {
    this.isEventsPayloadModalOpen = value;
  };

  setEventsPayloadData = (values: IEventsData) => {
    this.eventsPayloadData = values;
  };

  setLogsPayloadModalOpen = (value: boolean) => {
    this.isLogsPayloadModalOpen = value;
  };

  setLogsPayloadData = (values: IEventsData) => {
    this.logsPayloadData = values;
  };

  setFetchingMachines = (value: boolean) => {
    this.fetchingMachines = value;
  };

  setProvisionMachineId = (value: string) => {
    this.provisionMachineId = value;
  };

  setProvisionRequestId = (value: number) => {
    this.provisionRequestId = value;
  };

  setIsMachineOnline = (value: boolean) => {
    this.isMachineOnline = value;
  };

  setMachineInfo = (value: Partial<IMachineContent>) => {
    this.machineInfo = value;
  };

  setMachineDetailsPath = (value: string) => {
    this.machineDetailsPath = value;
  };

  setFetchingmachineData = (value: boolean) => {
    this.fetchingMachineData = value;
  };

  setSortBy = (value: string, direction = "ASC") => {
    this.sortBy = value;
    this.sortDirection = direction;
    this.getAllMachines(false);
  };

  setFetchingMachineCertificate = (value: boolean) => {
    this.fetchingMachineCertificate = value
  }
}

export default new MachineStore();
