import { API_BASE_URL } from "../../config/envConstants";
import { IResponse } from "../../interfaces/api";
import {
  IAddMachineReq,
  IMachineContent,
  IMachineEventsPayload,
  IMachineProvisionReq,
  IMachineProvisionRes,
  IMachineProvisionStatusRes,
  IMachineStatusRes,
  IMachineTemplates,
  IMetricsRequest,
  IPinUnpinMachineRes,
  ISnapshotRequest,
  IUpdateMachineReq,
} from "../../interfaces/machinesInterfaces";
import { removeEmpty } from "../../utils/object";
import { convertToQuery } from "../../utils/queryParams";
import { CloudApiClient } from "../cloudApiClient";
import { MACHINES_API_URL, API_URL_SUFFIX, API_VERSION } from "../constants";

export const addMachineApi = (
  body: IAddMachineReq,
): Promise<IResponse<IMachineContent>> => {
  return CloudApiClient.httpPost(`${MACHINES_API_URL}/add`, body);
};

export const UpdateMachineInfoApi = (
  id: string,
  body: IUpdateMachineReq,
): Promise<IResponse<boolean>> => {
  return CloudApiClient.httpPut(`${MACHINES_API_URL}/${id}/update`, body);
};

export const getMachinesApi = (
  pageNo: number,
  pageSize: number,
  sortBy: string,
  sortDirection: string,
  searchText: string,
  tags: { key: string; value: string }[],
): Promise<IResponse<IMachineEventsPayload>> => {
  const query = convertToQuery(
    removeEmpty({
      searchText,
      pageNo,
      pageSize,
      sortBy: sortBy,
      sortDirection: sortDirection,
    }),
  );
  return CloudApiClient.httpPost(`${MACHINES_API_URL}/list?${query}`, tags);
};

export const getSingleMachine = (
  machineId: string,
): Promise<IResponse<IMachineContent>> => {
  return CloudApiClient.httpGet(`${MACHINES_API_URL}/${machineId}`);
};

export const getPinnedMachinesApi = (
  pageSize: number,
  userId: string,
): Promise<IResponse<IMachineEventsPayload>> => {
  const query = convertToQuery({
    userAccountId: userId,
    pageSize,
    pinned: true,
    sortBy: "pinnedMachine.createdAt",
    sortDirection: "DESC",
  });
  return CloudApiClient.httpGet(`${MACHINES_API_URL}/get?${query}`);
};

export const pinUnpinMachine = (
  id: string,
  pinStatus: boolean,
): Promise<IResponse<IPinUnpinMachineRes>> => {
  const query = convertToQuery({
    toPin: pinStatus,
  });
  return CloudApiClient.httpPost(
    `${API_BASE_URL + API_URL_SUFFIX + API_VERSION}/pin-machine/${id}?${query}`,
    {},
  );
};

export const getMachineTemplates = (): Promise<
  IResponse<IMachineTemplates[]>
> => {
  return CloudApiClient.httpGet(
    `${API_BASE_URL + API_URL_SUFFIX + API_VERSION}/machine-templates`,
  );
};

// Machine details Apis

export const getMachineStateApi = (body: any): any => {
  return CloudApiClient.httpPost(`${MACHINES_API_URL}/state`, body);
};

export const getMachineEventsApi = (body: any): any => {
  return CloudApiClient.httpPost(`${MACHINES_API_URL}/events`, body);
};

export const getMachineLogsApi = (body: any): any => {
  return CloudApiClient.httpPost(`${MACHINES_API_URL}/logs`, body);
};

export const getMachineMetricsAggregateApi = (body: any): any => {
  return CloudApiClient.httpPost(`${MACHINES_API_URL}/metrics/aggregate`, body);
};

export const resetOrUnlinkMachine = (
  machineId: string,
  deleteOption: number,
) => {
  return CloudApiClient.httpDelete(`${MACHINES_API_URL}/remove/${machineId}`);
};

export const getMachineMetrics = (body: IMetricsRequest) => {
  return CloudApiClient.httpPost(`${MACHINES_API_URL}/metrics`, body);
};

export const getSnapshotsApi = () => {
  return CloudApiClient.httpGet(`${MACHINES_API_URL}/snapshots`);
};

export const addSnapShotApi = (body: ISnapshotRequest) => {
  return CloudApiClient.httpPost(`${MACHINES_API_URL}/snapshots`, body);
};

export const addMachineProvisionApi = (
  body: IMachineProvisionReq,
): Promise<IResponse<IMachineProvisionRes>> => {
  return CloudApiClient.httpPost(
    `${MACHINES_API_URL}/provision-requests/new`,
    body,
  );
};

export const getMachineProvisionStatus = (
  id: number,
): Promise<IResponse<IMachineProvisionStatusRes>> => {
  return CloudApiClient.httpPost(
    `${MACHINES_API_URL}/provision-requests/status?id=${id}`,
    {},
  );
};

export const requestEnableMachineIp = (machine_id_alias: string) => {
  return CloudApiClient.httpPut(
    `${MACHINES_API_URL}/${machine_id_alias}/ip/enable`,
    {},
  );
};

export const requestDisableMachineIp = (machine_id_alias: string) => {
  return CloudApiClient.httpPut(
    `${MACHINES_API_URL}/${machine_id_alias}/ip/disable`,
    {},
  );
};

export const requestEnableMachineDns = (machineId: number) => {
  return CloudApiClient.httpPost(
    `${MACHINES_API_URL}/${machineId}/request-enable-dns`,
    {},
  );
};

export const requestDisableMachineDns = (machineId: number) => {
  return CloudApiClient.httpPut(
    `${MACHINES_API_URL}/${machineId}/request-disable-dns`,
    {},
  );
};

export const updateTelemetry = (machineId: string, body) => {
  return CloudApiClient.httpPost(
    `${MACHINES_API_URL}/${machineId}/telemetry`,
    body,
  );
};

export const updateMachineInfo = (machineId: string, body) => {
  return CloudApiClient.httpPut(
    `${MACHINES_API_URL}/${machineId}/update`,
    body,
  );
};

export const CheckAliasApi = (alias: string): Promise<any> => {
  return CloudApiClient.httpGet(
    `${MACHINES_API_URL}/check-alias-inuse?alias=${alias}`,
  );
};

export const getFirewallRules = (
  machine_id_alias: string,
  direction: string,
) => {
  return CloudApiClient.httpGet(
    `${MACHINES_API_URL}/${machine_id_alias}/firewall-rules?direction=${direction}&sortDirection=ASC&all`,
  );
};

export const updateFirewallRule = (
  machine_id_alias: string,
  ruleId: string,
  body,
) => {
  return CloudApiClient.httpPut(
    `${MACHINES_API_URL}/${machine_id_alias}/firewall-rules/${ruleId}/update`,
    body,
  );
};

export const addFirewallRule = (machine_id_alias: string, body) => {
  return CloudApiClient.httpPut(
    `${MACHINES_API_URL}/${machine_id_alias}/firewall-rules/add`,
    body,
  );
};

export const deleteFirewallRule = (
  machine_id_alias: string,
  ruleId: string,
) => {
  return CloudApiClient.httpDelete(
    `${MACHINES_API_URL}/${machine_id_alias}/firewall-rules/${ruleId}/delete`,
  );
};

export const getMachineCertificate = (alias: string): Promise<any> => {
  return CloudApiClient.httpGet(`${MACHINES_API_URL}/${alias}/certificate`);
};

export const getPreviousCertificates = (alias: string): Promise<any> => {
  return CloudApiClient.httpGet(
    `${MACHINES_API_URL}/${alias}/revoked/certificate`,
  );
};

 export const downloadMachineCertificate = (
   alias: string,
   url: string,
 ): Promise<any> => {
   return CloudApiClient.httpGet(`${MACHINES_API_URL}/${alias}/${url}`);
 };

export const generateMachineCertificate = (alias: string): Promise<any> => {
  return CloudApiClient.httpPost(
    `${MACHINES_API_URL}/certificate/re-issue/${alias}`,
    {},
  );
};

export const getMachineAppServices = (alias: string): Promise<any> => {
  return CloudApiClient.httpGet(`${MACHINES_API_URL}/app-services/${alias}`);
};