export const API_BASE_URL = process.env.NEXT_PUBLIC_API_BASE_URL;

export const KRATOS_API_URL = process.env.NEXT_PUBLIC_KRATOS_API_URL;

export const LC_BASE_URL = process.env.NEXT_PUBLIC_LC_BASE_URL;

export const STORE_BASE_URL = process.env.NEXT_PUBLIC_STORE_BASE_URL;

export const isDev = process.env.NEXT_PUBLIC_NODE_ENV === "dev";

export const GITHUB_CLIENT_ID = process.env.NEXT_PUBLIC_GITHUB_CLIENT_ID;

export const WEBAPP_URL = process.env.NEXT_PUBLIC_WEBAPP_URL;

export const WEBSITE_DOCS_URL = process.env.NEXT_PUBLIC_DOCS_URL;

export const GITHUB_APPLICATION_ID =
  process.env.NEXT_PUBLIC_GITHUB_APPLICATION_ID;

export const BLOG_HOST_URL = process.env.NEXT_PUBLIC_BLOG_HOST_URL;
export const FORUMS_HOST_URL = process.env.NEXT_PUBLIC_FORUMS_HOST_URL;

export const PUSHER_KEY = process.env.NEXT_PUBLIC_PUSHER_APP_KEY;

export const SOKETI_HOST = process.env.NEXT_PUBLIC_WEB_SOCKET_HOST;

export const PUSHER_CLUSTER = process.env.NEXT_PUBLIC_WEB_SOCKET_CLUSTER;

export const MECHA_WEBSITE_URL = process.env.NEXT_PUBLIC_WEBSITE_URL;

export const TURNSTILE_SITE_KEY = process.env.NEXT_PUBLIC_TURNSTILE_SITE_KEY;

export const TURNSTILE_TESTING_SITE_KEY =
  process.env.NEXT_PUBLIC_TURNSTILE_TESTING_SITE_KEY;

export const TURNSTILE_DUMMY_TOKEN =
  process.env.NEXT_PUBLIC_TURNSTILE_DUMMY_TOKEN;

export const E2E_DISCOURSE_URL = process.env.NEXT_SERVER_E2E_DISCOURSE_URL;
export const DISCOURSE_URL = process.env.NEXT_PUBLIC_DISCOURSE_URL;

export const DISCOURSE_SECRET = process.env.NEXT_SERVER_DISCOURSE_SECRET;

export const INSTATUS_MECHA_URL = process.env.NEXT_PUBLIC_INSTATUS_MECHA_URL;

export const PRIVACY_URL = process.env.NEXT_PUBLIC_MECHA_PRIVACY_URL;
export const TERMS_URL = process.env.NEXT_PUBLIC_MECHA_TERMS_URL;
export const DOCS_URL = process.env.NEXT_PUBLIC_MECHA_DOCS_URL;

export const loggingEnabled = process.env.NEXT_PUBLIC_LOGGING_ENABLED
  ? process.env.NEXT_PUBLIC_LOGGING_ENABLED.toLowerCase() === "true"
  : false;
