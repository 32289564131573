import React from "react";

import CheckCircleIcon from "../assets/svg/CheckCircleIcon";
import { useTheme } from "next-themes";
import Loader from "../assets/svg/LoaderIcon";
import toast from "react-hot-toast";
import CrossCircleIcon from "../assets/svg/CrossCircleIcon";

const toastTypeData = [
  {
    textColor: "white",
    type: "SUCCESS",
    icon: (
      <CheckCircleIcon className="w-5 h-5 mr-2 flex-shrink-0" color="#34C759" />
    ),
    bgColor: "",
  },
  {
    textColor: "white",
    type: "ERROR",
    icon: <CrossCircleIcon className="w-5 h-5 text-red-500 mr-2" />,
    bgColor: "",
  },
  {
    textColor: "white",
    type: "LOADING",
    icon: <Loader className="mr-2 w-5 h-5 text-primary-600 animate-spin" />,
    bgColor: "",
  },
];

export type toastTypes = {
  toastType: "SUCCESS" | "ERROR" | "LOADING";
  msg: string;
  duration?: number;
};

export const customToast = (data: toastTypes) => {
  const toastId = `${data.msg}_toast_id`;
  const ToastOptions = {
    id: toastId,
    className: "customToaster",
    // position: "top-center",
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    style: {
      background: "transparent",
      boxShadow: "none",
      fontSize: "14px",
      padding: 0,
      margin: 0,
      zIndex: 9999999999,
    },
    duration: data.duration | 3000,
  };

  // tslint:disable-next-line: no-any
  const CustomMessage = (props: any) => {
    const { theme } = useTheme();
    return (
      <div
        className={`font-poppins flex flex-row px-3 py-2 border border-custom-50 dark:border-mecha-gray-140 rounded-md justify-center z-modalOptions ${
          theme === "dark"
            ? "bg-custom-1030 text-custom-50"
            : "bg-custom-1050 text-custom-410"
        }`}
      >
        {toastTypeData.find(t => t.type === data.toastType)?.icon}
        <p className="text-sm inline-flex items-center justify-start">
          {props.message}
        </p>
      </div>
    );
  };
  // Dismiss all toasts at once
  toast.remove();

  toast(<CustomMessage message={data.msg} />, ToastOptions);
};
