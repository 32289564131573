import { getCurrentTheme } from "../../../../../../utils/getCurrentTheme";

export enum metricsDataConstants {
  CPU = "CPU",
  DISK = "DISK",
  LOAD = "LOAD",
  FILES = "FILES",
  NETWORK = "NETWORK",
  MEMORY = "MEMORY",
}

const chartYScale = (yCoordinateText, currTheme) => {
  const title = {
    display: true,
    text: ` Value in ${yCoordinateText || ""}`,
  };
  const grid = {
    display: true,
    color:
      currTheme === "dark" ? "rgba(255, 255, 255, 0.1)" : "rgba(0, 0, 0, 0.1)",
    ticks: {
      beginAtZero: true,
    },
  };

  if (yCoordinateText) {
    return {
      title,
      grid,
    };
  } else {
    return {
      grid,
    };
  }
};

export const metricsColorScheme = [
  "rgb(48, 127, 226)",
  "rgb(153, 108, 250)",
  "rgb(231, 142, 169)",
  "rgb(255, 255, 255)",
  "rgb(240, 194, 54)",
];

export const chartOptions = (yCoordinateText: string, uniformLabelsSet: string[]) => {
  const currTheme = getCurrentTheme();
  return {
    type: "line",
    responsive: true,
    height: 200,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        position: "nearest" as const,
        external: context => externalTooltipHandler(context, currTheme),
      },
    },
    interaction: {
      intersect: false,
      mode: "index" as const,
    },
    scales: {
      x: {
        grid: {
          display: false,
          color:
            currTheme === "dark"
              ? "rgba(255, 255, 255, 0.1)"
              : "rgba(0, 0, 0, 0.1)",
        },
        ticks: {
          display: true,
          autoSkip: false,
          maxRotation: 0,
          callback: function (value) {
            const label = this.getLabelForValue(value);
            return uniformLabelsSet.includes(label) ? label.replace(" 00:00", "") : "";
          },
        },
      },
      y: chartYScale(yCoordinateText, currTheme),
    },
  };
};

const getOrCreateTooltip = chart => {
  let tooltipEl = chart.canvas.parentNode.querySelector("div");

  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.style.background = "rgba(0, 0, 0, 0.7)";
    tooltipEl.style.borderRadius = "3px";
    tooltipEl.style.color = "white";
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = "none";
    tooltipEl.style.position = "absolute";
    tooltipEl.style.transform = "translate(-50%, 0)";
    tooltipEl.style.transition = "all .1s ease";

    const table = document.createElement("table");
    table.style.margin = "0px";

    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

const externalTooltipHandler = (context, currTheme) => {
  // Tooltip Element
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set Text
  if (tooltip.body) {
    const titleLines = tooltip.title || [];
    const bodyLines = tooltip.body.map(b => b.lines);

    const tableHead = document.createElement("thead");

    titleLines.forEach(title => {
      const tr = document.createElement("tr");
      tr.style.borderWidth = "0px";
      tr.style.textAlign = "left"; // Align title text to left

      const th = document.createElement("th");
      th.style.borderWidth = "0px";
      th.style.margin = "20px !important";
      th.style.fontSize = "14px"; // Set font size to 14px
      const text = document.createTextNode(title);

      th.appendChild(text);
      tr.appendChild(th);
      tableHead.appendChild(tr);
    });

    const tableBody = document.createElement("tbody");
    tableBody.style.borderWidth = "0px";
    bodyLines.forEach((body, i) => {
      const colors = tooltip.labelColors[i];

      const span = document.createElement("span");
      span.style.background = colors.borderColor;
      span.style.borderColor = colors.borderColor;
      span.style.borderWidth = "0px";
      span.style.marginRight = "10px";
      span.style.height = "8px";
      span.style.width = "8px";
      span.style.display = "inline-block";

      const tr = document.createElement("tr");
      tr.style.backgroundColor = "inherit";
      tr.style.borderWidth = "0px";
      tr.style.textAlign = "left"; // Align body text to left

      const td = document.createElement("td");
      td.style.borderWidth = "0px";
      td.style.padding = "4px 0px";
      td.style.display = "flex";
      td.style.alignItems = "center";
      td.style.flexWrap = "nowrap";
      td.style.fontSize = "14px"; // Set font size to 14px

      const textN = document.createElement("p");
      textN.style.whiteSpace = "nowrap";
      textN.style.borderWidth = "0px";
      textN.style.wordBreak = "keep-all";

      const text = document.createTextNode(body);

      td.appendChild(span);
      td.appendChild(text);
      tr.appendChild(td);
      tableBody.appendChild(tr);
    });

    const tableRoot = tooltipEl.querySelector("table");

    // Remove old children
    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove();
    }

    // Add new children
    tableRoot.appendChild(tableHead);
    tableRoot.appendChild(tableBody);
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = positionX + tooltip.caretX + "px";
  tooltipEl.style.top = positionY + tooltip.caretY + "px";
  tooltipEl.style.font = "14px Arial";
  tooltipEl.style.padding = "10px";
  tooltipEl.style.zIndex = "999";
  tooltipEl.style.width = "max-content";
  tooltipEl.style.backgroundColor = `${
    currTheme === "dark" ? "#2C2F36" : "#DNDDE7"
  }`;
};
