import { IEventData } from "../libs/EventTracker";

export enum EventTrackingCodes {
  USERNAME_UPDATED = "Username updated",
  EMAIL_UPDATED = "Email updated",
  "2FA_UPDATE" = "2FA updated",
  PROFILE_UPDATE = "Profile updated",
  AVATAR_UPDATE = "Avatar updated",
  TWO_FA_ENABLED = "2FA Enabled",
  TWO_FA_DISABLED = "2FA Disabled",
  PASSWORD_UPDATED = "Password updated",
  USER_ACCOUNT_DELETED = "User account deleted",
  BACKUP_CODES_COPIED = "BAckup codes copied",
  BACKUP_CODES_DOWNLOADED = "Backup codes downloaded",
  SIGN_UP = "Sign up",
  SIGN_IN = "Sign in",
  PASSWORD_RESET = "Password reset",
  EMAIL_VERIFIED = "Email verified",

  ADD_MACHINE = "New Machine Added",
  MACHINE_INFO = "Machine Information updated",
  MACHINE_CONFIG = "Machine Config updated",
  UNLINK_MACHINE = "Machine Unlinked",
  TELEMETRY_UPDATED = "Machine Telemetry Updated",
  GENERATE_MACHINE_CERTIFICATE = "Regenerated Machine Certificate",
  GRAFANA_METRICS = "Open Metrics in Grafana",
  GRAFANA_LOGS = "Open Logs in Grafana",

  ADD_NETWORK = "New Network Added",
  UPDATE_NETWORK = "Network Details Updated",
  NETWORKING_ENABLED = "Networking Enabled",
  NETWORKING_DISABLED = "Networking Disabled",

  APIKEY_ADDED = "API Key Added",
  APIKEY_DELETED = "API Key Deleted",
}

export interface ChangeUsernameEventData extends IEventData {
  username: string;
}

export interface ChangeEmailEventData extends IEventData {
  email: string;
}

export interface Change2FAEventData extends IEventData {
  twoFactorAuthentication: string;
}
export interface EnableMachineConfigurations {
  machineId: string;
  value?: string;
}

export interface ChangeProfileData extends IEventData {
  [key: string]: any;
}

export interface ChangeMachineName extends IEventData {
  name: string;
}

export interface UpdateMachineConfig extends IEventData {
  machineConfig: {
    section: string;
    data: string;
  };
}
