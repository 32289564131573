import { API_BASE_URL } from "../../config/envConstants";
import { removeEmpty } from "../../utils/object";
import { convertToQuery } from "../../utils/queryParams";
import { CloudApiClient } from "../cloudApiClient";

const API_VERSION = "v1";
const NODE_API_PREFIX = `/networks/node`;
const DOMAIN_API_PREFIX = `/networking/domain`;
const NETWORK_API_PREFIX = `/networks`;
const API_URL_SUFFIX = "/api/";
const API_URL = API_BASE_URL + API_URL_SUFFIX + API_VERSION;
const NODE_BASE_URL = API_URL + NODE_API_PREFIX;
const DOMAIN_BASE_URL = API_URL + DOMAIN_API_PREFIX;
const NETWORK_BASE_URL = API_URL + NETWORK_API_PREFIX;

interface INodeRenew {
  nodeId: string;
  leaseDuration: string;
}

export const nodeDeactivateApi = (nodeId: string) => {
  return CloudApiClient.httpDelete(`${NODE_BASE_URL}/${nodeId}/delete`);
};

export const nodeRenewApi = (body: INodeRenew) => {
  return CloudApiClient.httpPut(`${NODE_BASE_URL}/renew`, body);
};

export const setDefaultNetwork = (networkId: string) => {
  return CloudApiClient.httpPut(
    `${NETWORK_BASE_URL}/${networkId}/set-default`,
    {},
  );
};

export const fetchDomainsList = () => {
  return CloudApiClient.httpGet(`${DOMAIN_BASE_URL}/list`);
};

export const fetchNetworkDetails = (networkId: string) => {
  return CloudApiClient.httpGet(`${NETWORK_BASE_URL}/${networkId}`);
};

export const fetchNodes = (networkId: string, searchText: string) => {
  const query = convertToQuery(
    removeEmpty({
      searchText,
      sortDirection: "DESC",
    }),
  );
  return CloudApiClient.httpGet(
    `${NETWORK_BASE_URL}/${networkId}/nodes?${query}`,
  );
};

export const fetchNodeDetails = (nodeId: string) => {
  return CloudApiClient.httpGet(`${NODE_BASE_URL}/${nodeId}/details`);
};

export const checkDnsInUseApi = (dnsName: string) => {
  return CloudApiClient.httpGet(
    `${API_URL}/app-services/check-dns-name-inuse?dnsName=${dnsName}`,
  );
};

export const checkNodeDnsInUseApi = (dnsName: string, networkId:string) => {
  return CloudApiClient.httpGet(
    `${API_URL}/networks/nodes/check-dns-name-inuse?dnsName=${dnsName}&networkId=${networkId}`,
  );
};

export const deleteNetworkApi = (networkId: string) => {
  return CloudApiClient.httpDelete(`${NETWORK_BASE_URL}/${networkId}`);
};

export const addNodeApi = body => {
  return CloudApiClient.httpPost(`${NODE_BASE_URL}/enable`, body);
};

export const addNetworkApi = body => {
  return CloudApiClient.httpPost(`${API_URL}/networks`, body);
};

export const updateNetworkApi = (body, id) => {
  return CloudApiClient.httpPut(`${API_URL}/networks/${id}`, body);
};

export const updateDomainApi = (body, networkId) => {
  return CloudApiClient.httpPut(`${DOMAIN_BASE_URL}/${networkId}`, body);
};

export const getNetworksApi = () => {
  return CloudApiClient.httpGet(`${API_URL}/networks?sortBy=createdAt&sortDirection=ASC`);
};

export const generateIPAddressApi = (networkId: string) => {
  return CloudApiClient.httpGet(
    `${API_URL}/networks/generate-ipv4?networkId=${networkId}`,
  );
};

export const enableNodeApi = body => {
  return CloudApiClient.httpPost(`${API_URL}/machines/networks/enable`, body);
};

export const disableNetworkingApi = (nodeId: string) => {
  return CloudApiClient.httpDelete(`${NETWORK_BASE_URL}/nodes/${nodeId}`);
};
