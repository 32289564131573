import createStore from "../createStore";
import authStore from "./authStore";
import machineStore from "./machineStore";
import metricesStore from "./machineMetricsStore";
import automationStore from "./automationStore";
import networkingStore from "./networkingStore";
import machineLogsStore from "./machineLogsStore";
import settingsStore from "./settingsStore";
import appServicesStore from "./appServicesStore";

export const store = {
  authStore,
  machineStore,
  machineLogsStore,
  metricesStore,
  automationStore,
  networkingStore,
  settingsStore,
  appServicesStore,
};
export const {
  Consumer: StoreConsumer,
  Provider: StoreProvider,
  useStore,
} = createStore(store);
