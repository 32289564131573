import { useRef } from "react";
import { showErrorToast } from "./errorsToast";
import { ErrorReporter } from "../libs/ErrorReporter";
import { downloadMachineCertificate } from "../api/machinesApis";
import { durations } from "../config/constants";
import { metricsDataConstants } from "../components/Machines/MachineDetails/MachineTabs/MetricsTab/chartConfig/chartConfiguration";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

export const continueRoute = () => {
  const storage = globalThis?.sessionStorage;
  const prevRoute = storage.getItem("prevPath");
  const currRoute = storage.getItem("currentPath");
  console.log("const storage = globalThis?.sessionStorage;", storage);
  if (
    !prevRoute ||
    (prevRoute && prevRoute == "null") ||
    prevRoute == currRoute
  ) {
    return "/machines";
  } else {
    return prevRoute;
  }
};

export const getLoginUrl = (url: string) => {
  const origin =
    typeof window !== "undefined" && window.location.origin
      ? window.location.origin
      : "";
  const loginUrl = `/auth/signin?prevUrl=${origin}${url}`;
  return loginUrl;
};

export default function useDebounce(func, delay) {
  const timerRef = useRef(null);
  return (...args) => {
    if (timerRef.current !== null) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      func(...args);
    }, delay);
  };
}

export const getFormattedDate = (date: Date) => {
  const d = date ? date : new Date();
  return {
    year: d?.getFullYear(),
    month: d?.getMonth() + 1,
    day: d?.getDate(),
  };
};

export const getFormattedTimeStamp = (values: string) => {
  const dateTime = new Date(values);
  const formattedDate = dateTime.toLocaleString("en-US", {
    timeStyle: "short",
    dateStyle: "medium",
  });
  return formattedDate;
};

export type IMetricsResult = IMetricsObj[];
export interface IMetricsObj {
  metric: Metric;
  values: [number, any][];
}
export interface Metric {
  state: any;
  device: any;
  machine_id: string;
  direction: any;
  type: any;
}

export const metricsDataComputing = (m: IMetricsResult, chartsXLabels = []) => {
  const metrics = m.slice();
  const xCoordinates = [];
  const computedData = { labels: [], metricsData: [] };
  if (metrics.length) {
    const newMetricsData = metrics.map((dataset, index) => {
      const newData = [];
      const chartData = new Map<number, any | null>([
        ...chartsXLabels,
        ...dataset.values,
      ]);
      metrics[index].values = [...chartData.entries()];
      for (const [key, value] of chartData.entries()) {
        newData.push(value);
      }
      return [dataset.metric, newData];
    });

    const firstMetricData = metrics[0]?.values;
    if (firstMetricData && firstMetricData.length > 1) {
      computedData.labels = xCoordinates;
      computedData.metricsData = newMetricsData;
    }
  }
  return computedData;
};

export const existingRulesArray = rules => {
  const pairsArr = [];
  rules.map(rule => {
    const rolesStr = rule.roles.split(",").sort();
    pairsArr.push(`${rule.protocol}::${rule.allowPorts}::${rolesStr}`);
  });
  return pairsArr;
};

export const downloadCertificate = async (alias, link, filename) => {
  try {
    const response = await downloadMachineCertificate(alias, link);
    downloadFile(response, `${alias}_${filename}.crt`);
  } catch (error) {
    showErrorToast(error);
    ErrorReporter.collect(error, "Error in downloading the certificate file");
  }
};

function downloadFile(data, filename) {
  const blob = new Blob([data], { type: "application/x-x509-ca-cert" });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.style.display = "none";
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
}

export const getDefaultMetricsDuration = () => {
  if (typeof window !== "undefined") {
    const storedDuration = localStorage.getItem("metricsDuration");
    if (storedDuration) {
      return JSON.parse(storedDuration);
    }
  }
  return durations[0];
};

export const generateUniformTimeStamps = (startTimestamp, endTimestamp, intervalMinutes, labelFormat) => {
  const interval = intervalMinutes * 60;
  const startDate = dayjs(startTimestamp * 1000).startOf('hour');
  let currentTimestamp = startDate.unix();

  const timestamps = [];

  while (currentTimestamp <= endTimestamp) {
    timestamps.push(dayjs.unix(currentTimestamp).format(labelFormat));
    currentTimestamp += interval;
  }

  return timestamps;
};

export const getMetricsLabel = (data, metricType) => {
  let label = "uasge";
  switch (metricType) {
    case metricsDataConstants.CPU:
      label = `${data?.[0].system_cpu_logical_number}`;
      break;
    case metricsDataConstants.DISK:
      label = `${data?.[0]?.direction}`;
      break;
    case metricsDataConstants.FILES:
      label = `${data?.[0]?.state}`;
      break;
    case metricsDataConstants.LOAD:
      label = "load";
      break;
    case metricsDataConstants.MEMORY:
      label = `memory - ${data?.[0]?.state}`;
      break;
    case metricsDataConstants.NETWORK:
      label = `${data?.[0]?.direction}`;
      break;
  }
  return label;
};

export const getMachineActiveStatus = lastActiveAt => {
  dayjs.extend(utc);
  const parsedLastActiveAt = dayjs.utc(lastActiveAt);
  const lastActiveDifference = dayjs.utc().diff(parsedLastActiveAt) / 1000;
  return lastActiveDifference < 120;
};
