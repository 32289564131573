import {
  FORUMS_HOST_URL,
  MECHA_WEBSITE_URL,
  DOCS_URL,
  PRIVACY_URL,
  TERMS_URL,
  INSTATUS_MECHA_URL,
} from "./envConstants";

export const CONFIGURE_MACHINE_DOCS = "#";

export const ADD_NEW_MACHINE_DOCS = "#";

export const ENABLE_NETWORKING_DOCS = "#";

export const SSH_CONNECTIVITY_DOCS = "#";

export const DEVELOPER_CLI_DOCS = "#";

export const MECHA_DOCS_URL = DOCS_URL;

export const MECHA_PRIVACY_DOCS = PRIVACY_URL;

export const MECHA_TERMS_DOCS = TERMS_URL;

export const MECHA_GITHUB_PROFILE = "https://github.com/mecha-org";

export const MECHA_DISCORD_PROFILE = "https://discord.gg/GdXTjgju";

export const MECHA_FORUMS = FORUMS_HOST_URL;

export const MECHA_REDDIT_PROFILE = "https://reddit.com/r/mecha-org";

export const MECHA_WEBSITE_LINK = MECHA_WEBSITE_URL;

export const INSTATUS_URL = INSTATUS_MECHA_URL;

export const SSH_ON_MAC_OS = "#";

export const SSH_ON_OTHER_OS = "#";

export const CERTIFICATE_DOCS = "#";

export const TELEMETRY_DOCS = "#";

export const NETWORKING_DOCS = "#";

export const HIVE_COMPUTING_DOCS = "#";

export const APP_SERVICES_DOCS = "#"

export const SELF_HOSTED_APPLICATIONS_DOCS = "#";

export const SETUP_EXTERNAL_HOST_DOCS = "#";

export const HOST_PERSONAL_WEBSITES_DOCS = "#";

export const API_BASED_AUTOMATIONS_DOCS = "#";