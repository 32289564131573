import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { DETAILED_DATE_FORMAT } from "../config/constants";

export enum durationsEnum {
  "5m" = "5m",
  "15m" = "15m",
  "30m" = "30m",
  "1h" = "1h",
  "3h" = "3h",
  "6h" = "6h",
  "12h" = "12h",
  "24h" = "24h",
  "today" = "today",
  "yesterday" = "yesterday",
  "lastWeek" = "lastWeek",
  "thisWeek" = "thisWeek",
}

export const getTimeStamp = (dateTimeObj, timeMode) => {
  let result = dayjs(dateTimeObj).unix() * 1000000000;
  const type = "nanos";
  switch (type) {
    case "nanos":
      if (timeMode == "UTC") {
        dayjs.extend(utc);
        dayjs.extend(timezone);
        result = dayjs.utc(dateTimeObj).unix() * 1000000000;
      } else result = dayjs(dateTimeObj).unix() * 1000000000;
      break;
  }
  return result;
};

export const getDuration = (time: durationsEnum, mode: "UTC" | "LOCAL") => {
  let result;
  let dayJsMode = dayjs();
  if (mode === "UTC") {
    dayjs.extend(utc);
    dayJsMode = dayjs.utc();
  }

  switch (time) {
    case durationsEnum["5m"]:
      result = {
        fromView: dayJsMode.subtract(5, "minute").format(DETAILED_DATE_FORMAT),
        toView: dayJsMode.format(DETAILED_DATE_FORMAT),
        from: getTimeStamp(dayJsMode.subtract(5, "minute"), mode),
        to: getTimeStamp(dayJsMode, mode),
      };
      break;

    case durationsEnum["15m"]:
      result = {
        fromView: dayJsMode.subtract(15, "minute").format(DETAILED_DATE_FORMAT),
        toView: dayJsMode.format(DETAILED_DATE_FORMAT),
        from: getTimeStamp(dayJsMode.subtract(15, "minute"), mode),
        to: getTimeStamp(dayJsMode, mode),
      };
      break;

    case durationsEnum["30m"]:
      result = {
        fromView: dayJsMode.subtract(30, "minute").format(DETAILED_DATE_FORMAT),
        toView: dayJsMode.format(DETAILED_DATE_FORMAT),
        from: getTimeStamp(dayJsMode.subtract(30, "minute"), mode),
        to: getTimeStamp(dayJsMode, mode),
      };
      break;

    case durationsEnum["1h"]:
      result = {
        fromView: dayJsMode.subtract(1, "hour").format(DETAILED_DATE_FORMAT),
        toView: dayJsMode.format(DETAILED_DATE_FORMAT),
        from: getTimeStamp(dayJsMode.subtract(1, "hour"), mode),
        to: getTimeStamp(dayJsMode, mode),
      };
      break;

    case durationsEnum["3h"]:
      result = {
        fromView: dayJsMode.subtract(3, "hour").format(DETAILED_DATE_FORMAT),
        toView: dayJsMode.format(DETAILED_DATE_FORMAT),
        from: getTimeStamp(dayJsMode.subtract(3, "hour"), mode),
        to: getTimeStamp(dayJsMode, mode),
      };
      break;

    case durationsEnum["6h"]:
      result = {
        fromView: dayJsMode.subtract(6, "hour").format(DETAILED_DATE_FORMAT),
        toView: dayJsMode.format(DETAILED_DATE_FORMAT),
        from: getTimeStamp(dayJsMode.subtract(6, "hour"), mode),
        to: getTimeStamp(dayJsMode, mode),
      };
      break;

    case durationsEnum["12h"]:
      result = {
        fromView: dayJsMode.subtract(12, "hour").format(DETAILED_DATE_FORMAT),
        toView: dayJsMode.format(DETAILED_DATE_FORMAT),
        from: getTimeStamp(dayJsMode.subtract(12, "hour"), mode),
        to: getTimeStamp(dayJsMode, mode),
      };
      break;

    case durationsEnum["24h"]:
      result = {
        fromView: dayJsMode.subtract(24, "hour").format(DETAILED_DATE_FORMAT),
        toView: dayJsMode.format(DETAILED_DATE_FORMAT),
        from: getTimeStamp(dayJsMode.subtract(24, "hour"), mode),
        to: getTimeStamp(dayJsMode, mode),
      };
      break;

    case durationsEnum["today"]:
      result = {
        fromView: dayJsMode.startOf("day").format(DETAILED_DATE_FORMAT),
        toView: dayJsMode.format(DETAILED_DATE_FORMAT),
        from: getTimeStamp(dayJsMode.startOf("day"), mode),
        to: getTimeStamp(dayJsMode, mode),
      };
      break;

    case durationsEnum["yesterday"]:
      result = {
        fromView: dayJsMode
          .subtract(1, "day")
          .startOf("day")
          .format(DETAILED_DATE_FORMAT),
        toView: dayJsMode
          .subtract(1, "day")
          .endOf("day")
          .format(DETAILED_DATE_FORMAT),
        from: getTimeStamp(dayJsMode.subtract(1, "day").startOf("day"), mode),
        to: getTimeStamp(dayJsMode.subtract(1, "day").endOf("day"), mode),
      };
      break;

    case durationsEnum["thisWeek"]:
      result = {
        fromView: dayJsMode
          .startOf("week")
          .add(1, "day")
          .format(DETAILED_DATE_FORMAT),
        toView: dayJsMode.format(DETAILED_DATE_FORMAT),
        from: getTimeStamp(dayJsMode.startOf("week").add(1, "day"), mode),
        to: getTimeStamp(dayJsMode, mode),
      };
      break;

    case durationsEnum["lastWeek"]:
      result = {
        fromView: dayJsMode
          .startOf("week")
          .add(1, "day")
          .subtract(1, "week")
          .format(DETAILED_DATE_FORMAT),
        toView: dayJsMode
          .startOf("week")
          .endOf("day")
          .format(DETAILED_DATE_FORMAT),
        from: getTimeStamp(
          dayJsMode.startOf("week").add(1, "day").subtract(1, "week"),
          mode,
        ),
        to: getTimeStamp(dayJsMode.startOf("week").endOf("day"), mode),
      };
      break;
  }

  return result;
};

export const getDateFromTimeStamp = (
  timeStamp,
  timeMode,
  dateFormat = null,
) => {
  const conversionDivision = 1000000;
  const dateObj = new Date(timeStamp / conversionDivision);
  if (timeMode == "LOCAL") {
    return dateFormat
      ? dayjs(dateObj).format(dateFormat)
      : dayjs(dateObj).format();
  } else {
    return dateFormat
      ? dayjs.utc(dateObj).format(dateFormat)
      : dayjs.utc(dateObj).format();
  }
};
