import { action, observable, makeObservable } from "mobx";
import { getApiKeys } from "../../api/apiKeysApis";
import { ErrorReporter } from "../../libs/ErrorReporter";
import { ICommonCloudApiResponse } from "../../interfaces";

interface IApiKeyData {
  keyName: string;
  accessKeyId: string;
  description: string;
  expiryDate: string;
}
interface IGeneratedKeyData {
  name: string;
  description: string;
  expiryDate: string;
  accessKeyId: string;
  accessKeySecret: string;
}

const initialValues = {
  apiKeyModal: false,
  generateKeyModalStep: 1,
  generatedAPIKeyData: null,
  apiKeyList: [],
  fetchingKeys: true,
};

class SettingsStore {
  apiKeyModal: boolean;
  generateKeyModalStep: number;
  generatedAPIKeyData: IGeneratedKeyData;
  apiKeyList: IApiKeyData[];
  fetchingKeys: boolean;

  constructor() {
    this.reset();
    makeObservable(this, {
      apiKeyModal: observable,
      setApiKeyModal: action,
      generateKeyModalStep: observable,
      setGenerateKeyModalStep: action,
      generatedAPIKeyData: observable,
      setGeneratedAPIKeyData: action,
      apiKeyList: observable,
      setApiKeyList: action,
      fetchApiKeys: action,
      fetchingKeys: observable,
    });
  }

  reset = () => {
    Object.keys(initialValues).forEach(key => {
      this[key] = initialValues[key];
    });
  };

  setApiKeyModal = (value: boolean) => {
    this.apiKeyModal = value;
  };

  setGenerateKeyModalStep = (value: number) => {
    this.generateKeyModalStep = value;
  };

  setGeneratedAPIKeyData = (value: IGeneratedKeyData) => {
    this.generatedAPIKeyData = value;
  };

  setApiKeyList = (value: IApiKeyData[]) => {
    this.apiKeyList = value;
  };

  setFetchingKeys = (value: boolean) => {
    this.fetchingKeys = value;
  };

  fetchApiKeys = async () => {
    this.setFetchingKeys(true);
    try {
      const response: ICommonCloudApiResponse = await getApiKeys();
      if (!response?.success) {
        throw response;
      }
      const apiKeys = response.payload;
      this.setApiKeyList(apiKeys);
    } catch (err) {
      ErrorReporter.collect(err, "Error in fetching API Keys");
    } finally {
      this.setFetchingKeys(false);
    }
  };
}

export default new SettingsStore();
