import { useEffect } from "react";
import { useStore } from "../../storeManager/stores";
import { ErrorReporter } from "../../libs/ErrorReporter";
import Logger from "../../libs/Logger";

const WithAuth = (ComposedComponent: any): any => {
  const WithAuthentication = (props: any) => {
    const authStore = useStore().authStore;
    const log = new Logger({
      module: "Authentication",
      component: "WithAuthentication",
    });

    const getSession = async () => {
      //Initilize user in authStore
      try {
        // not calling whoami if it is a reset password page
        if (!props?.router?.state?.pathname.includes("/auth/")) {
          await authStore.initialize();
        }
      } catch (error) {
        ErrorReporter.collect(error, "Error while initializing auth store");
        log.warn(
          { error: error },
          "(getSession) - Error while initializing auth store",
        );
      }
    };

    useEffect(() => {
      getSession();
    });

    return <ComposedComponent {...props} />;
  };

  return WithAuthentication;
};

export default WithAuth;
