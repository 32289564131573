import { action, observable, makeObservable } from "mobx";
import { addAppServiceApi, deletAppServiceApi, getAppServiceApi, updateAppServiceApi } from "../../api/appServiceApis";
import { getMachineAppServices } from "../../api/machinesApis";
import { ErrorReporter } from "../../libs/ErrorReporter";


export interface IAppServiceBody {
  appName: string
  dnsName: string
  portMappings: IPortMapping[]
  machineIdOrAliasList: string[]
}

export interface IPortMapping {
  targetPort: number
  localPort: number
  description: string
}

const initialValues = {
  appServicesList: [],
  fetchingAppServices: true,
  fetchingMachineAppServices: true,
  appServiceModal: "",
  machineAppServices: []
};

class appServicesStore {
  appServicesList: any[];
  fetchingAppServices: boolean;
  fetchingMachineAppServices: boolean;
  appServiceModal: string;
  machineAppServices: any[];

  constructor() {
    this.reset();
    makeObservable(this, {
      appServicesList: observable,
      machineAppServices: observable,
      fetchAllAppServices: action,
      fetchingAppServices: observable,
      fetchingMachineAppServices: observable,
      appServiceModal: observable,
      setAppServiesModal: action,
      setFetchingAppServies: action,
      addAppService: action,
      deleteAppService: action,
      updateAppService: action,
      fetchMachineAppServices: action,
    });
  }

  reset = () => {
    Object.keys(initialValues).forEach(key => {
      this[key] = initialValues[key];
    });
  };

  setAppServicesList = (value: any[]) => {
    this.appServicesList = value;
  };

  fetchAllAppServices = async () => {
    this.fetchingAppServices = true;
    try {
      const response = await getAppServiceApi();
      const services = response.payload.content;
      this.setAppServicesList(services);
    } catch (err) {
      ErrorReporter.collect(err, "Error in fetching app-service");
    } finally {
      this.fetchingAppServices = false;
    }
  };

  setMachineAppServices = (value) => {
    this.machineAppServices = value;
  }

  fetchMachineAppServices = async (machineId: string) => {
    this.fetchingMachineAppServices = true;
    try {
      const response = await getMachineAppServices(machineId);
      const services = response.payload;
      this.setMachineAppServices(services);
    } catch (err) {
      ErrorReporter.collect(err, "Error in fetching app-service");
    } finally {
      this.fetchingMachineAppServices = false;
    }
  };

  addAppService = async (body: IAppServiceBody) => {
    try{
      const res = await addAppServiceApi(body);
      return res
    } catch (err) {
      ErrorReporter.collect(err, "Error in adding app-service");
      throw err;
    }
  }

  deleteAppService = async (appId: string) => {
    try {
      const res = await deletAppServiceApi(appId);
      await this.fetchAllAppServices();
      return res;
    } catch (err) {
      ErrorReporter.collect(err, "Error in deleting app-service");
      throw err;
    }
  }

  updateAppService = async (body: IAppServiceBody, appId: string) => {
    try{
      const res = await  updateAppServiceApi(body, appId);
      return res
    } catch (err) {
      ErrorReporter.collect(err, "Error in adding app-service");
      throw err;
    }
  }

  setAppServiesModal = (value: string) => {
    this.appServiceModal = value;
  };

  setFetchingAppServies = (value: boolean) => {
    this.fetchingAppServices = value;
  };

}

export default new appServicesStore();