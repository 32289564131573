import { action, computed, makeObservable, observable } from "mobx";

import {
  exportLogsAPI,
  getLogStreamsAPI,
  getLogsAPI,
} from "../../api/machinesApis/logsApi";
import { API_STATUS } from "../../config/constants";
import { getDuration, getTimeStamp } from "../../utils/dateTime";
import { ErrorReporter } from "../../libs/ErrorReporter";
import dayjs from "dayjs";

interface IAPIStatus {
  error: boolean;
  message: string;
  status: API_STATUS;
}
const LogsLimit = 100;
type MachineLogsStoreFields = {
  loadingLogs: boolean;
  loadingStream: boolean;
  loadingExport: boolean;
  loadingSearchResult: boolean;
  pageLoading: boolean;
  streamList: string[];
  logsList: any[];

  isLoadMoreEnabled: boolean;
  loadingMoreLogs: boolean;

  searchText: string;
  startTimeStamp: number;
  endTimeStamp: number;
  selectedStream: string;
  selectedDuration: any;
  exportStatus: IAPIStatus;
  tailLogsStatus: "ON" | "OFF";

  lastLogTimeStamp: string;
  timeMode: "UTC" | "LOCAL";
};

const initialValues: MachineLogsStoreFields = {
  loadingLogs: true,
  loadingStream: true,
  pageLoading: true,
  loadingSearchResult: false,
  loadingExport: false,
  tailLogsStatus: "OFF",

  streamList: [],
  logsList: [],
  isLoadMoreEnabled: false,
  loadingMoreLogs: false,

  searchText: "",
  selectedStream: "NONE",
  selectedDuration: {
    label: "Last 12 hours",
    value: "12h",
    startTime: null,
    endTime: null,
  },
  startTimeStamp: null,
  endTimeStamp: null,
  exportStatus: {
    error: false,
    message: "",
    status: API_STATUS.IDLE,
  },
  lastLogTimeStamp: "",
  timeMode: "LOCAL",
};

class MachineLogsStore {
  loadingLogs: boolean = initialValues.loadingLogs;
  loadingStream: boolean;
  pageLoading: boolean;
  loadingExport: boolean;
  streamList: string[];
  logsList: any[];

  isLoadMoreEnabled: boolean = initialValues.isLoadMoreEnabled;
  loadingMoreLogs: boolean = initialValues.loadingMoreLogs;

  searchText: string;
  startTimeStamp: number = initialValues.selectedDuration.from;
  endTimeStamp: number = initialValues.selectedDuration.to;
  selectedStream: any = initialValues.selectedStream;
  selectedDuration: any = initialValues.selectedDuration;
  exportStatus: IAPIStatus = initialValues.exportStatus;
  tailLogsStatus = initialValues.tailLogsStatus;
  lastLogTimeStamp = initialValues.lastLogTimeStamp;
  loadingSearchResult = initialValues.loadingSearchResult;
  timeMode = initialValues.timeMode;

  constructor() {
    this.reset();

    makeObservable(this, {
      pageLoading: observable,
      loadingLogs: observable,
      loadingStream: observable,
      loadingExport: observable,
      exportStatus: observable,
      tailLogsStatus: observable,

      streamList: observable,
      logsList: observable,

      searchText: observable,
      startTimeStamp: observable,
      endTimeStamp: observable,
      selectedStream: observable,
      selectedDuration: observable,

      setLoading: action,
      setPageLoading: action,
      setLoadingStream: action,
      setLoadingExport: action,
      setStreamList: action,
      setLogsList: action,

      setSearchText: action,
      setStartTimeStamp: action,
      setEndTimeStamp: action,
      fetchLogs: action,
      fetchLogStream: action,
      exportLogs: action,
      setTailLogStatus: action,
      setSelectedStream: action,

      isLoadMoreEnabled: observable,
      enableLoadMore: action,

      loadingMoreLogs: observable,
      setLoadingMoreLogs: action,

      fetchMoreLogs: action,
      fetchLogsWithTailenabled: action,

      allowAction: computed,

      lastLogTimeStamp: observable,
      // setLastLogTimeStamp: action,

      loadingSearchResult: observable,
      setLoadingSearchResult: action,

      timeMode: observable,
      setTimeMode: action,

      reset: action,
    });

    const defaultDuration = getDuration(
      this.selectedDuration.value,
      this.timeMode,
    );
    this.selectedDuration.startTime = this.startTimeStamp =
      defaultDuration.from;
    this.selectedDuration.endTime = this.endTimeStamp = defaultDuration.to;
  }

  reset = () => {
    Object.keys(initialValues).forEach(key => {
      this[key] = initialValues[key];
    });
  };

  setLoading = (value: boolean) => {
    this.loadingLogs = value;
  };

  setLoadingStream = (value: boolean) => {
    this.loadingStream = value;
  };

  setLoadingExport = (value: boolean) => {
    this.loadingExport = value;
  };

  setLoadingSearchResult = (value: boolean) => {
    this.loadingSearchResult = value;
  };
  setPageLoading = (value: boolean) => {
    this.pageLoading = value;
  };

  setStreamList = (value: string[]) => {
    this.streamList = value;
  };

  setLogsList = (value: any[]) => {
    this.logsList = value;
  };

  setTimeMode = value => {
    this.timeMode = value;
  };
  enableLoadMore = (value: boolean) => {
    this.isLoadMoreEnabled = value;
  };
  setLoadingMoreLogs = (value: boolean) => {
    this.loadingMoreLogs = value;
  };

  setSearchText = (value: string) => {
    this.searchText = value;
  };
  setStartTimeStamp = (value: number) => {
    this.startTimeStamp = value;
  };
  setEndTimeStamp = (value: number) => {
    this.endTimeStamp = value;
  };
  setSelectedStream = (value: any) => {
    this.selectedStream = value;
  };

  setSelectedDuration = (value: any) => {
    this.selectedDuration = value;
  };

  setExportStatus = (status: IAPIStatus) => {
    this.exportStatus = status;
  };

  setTailLogStatus = (status: "ON" | "OFF") => {
    this.tailLogsStatus = status;
  };

  get allowAction() {
    return !this.loadingStream && !this.loadingMoreLogs;
  }

  // fetchLogs_legacy = async machineId => {
  //   if (this.selectedStream === "NONE") {
  //     this.setLoading(false);
  //     return;
  //   }

  //   if (this.isLoadMoreEnabled) {
  //     this.setLoadingMoreLogs(true);
  //     this.enableLoadMore(false);
  //   } else {
  //     this.setLoading(true);
  //   }

  //   if (this.refreshLogs) {
  //     this.setLoading(true);
  //     this.setLoadingMoreLogs(false);
  //     this.setLogsList([]);
  //   }

  //   try {
  //     const data = await getLogsAPI(
  //       {
  //         start: this.startTimeStamp,
  //         end: this.endTimeStamp,
  //         search: this.searchText,
  //         stream: this.selectedStream,
  //         limit: LogsLimit,
  //       },
  //       machineId,
  //     );
  //     if (data.payload?.status === "success") {
  //       if (data.payload?.data?.result.length === 0) {
  //         this.enableLoadMore(false);
  //       } else {
  //         const streamLogs = data.payload?.data?.result?.[0]?.values || [];
  //         const streamData =
  //           data.payload?.data?.result?.[0]?.stream.stream_name || "";
  //         if (streamLogs.length == LogsLimit) {
  //           this.enableLoadMore(true);
  //         } else {
  //           this.enableLoadMore(false);
  //         }
  //         if (this.tailLogsStatus == "ON" || this.refreshLogs) {
  //           this.setLogsList([...streamLogs]);
  //         } else {
  //           this.setLogsList([...this.logsList, ...streamLogs]);
  //         }
  //         this.setSelectedStream(streamData);
  //       }
  //     }
  //   } catch (error) {
  //     ErrorReporter.collect(error, "Error in fetching logs");
  //   } finally {
  //     this.setLoading(false);
  //     this.setPageLoading(false);
  //     this.setRefreshLogs(false);
  //     this.setLoadingMoreLogs(false);
  //   }
  // };

  callLogsApi = async machineId => {
    const data = await getLogsAPI(
      {
        start: this.startTimeStamp,
        end: this.endTimeStamp,
        search: this.searchText,
        stream: this.selectedStream,
        limit: LogsLimit,
      },
      machineId,
    );

    return data;
  };

  fetchLogs = async (machineId, isSearchQuery = false) => {
    if (this.selectedStream === "NONE") {
      this.setLoading(false);
      return;
    }

    if (isSearchQuery) {
      this.setLoadingSearchResult(true);
    } else {
      this.setLoading(true);
    }

    if (this.selectedDuration.value == "custom") {
      this.setStartTimeStamp(this.selectedDuration.startTime);
      this.setEndTimeStamp(this.selectedDuration.endTime);
    } else {
      const durationValue = getDuration(
        this.selectedDuration.value,
        this.timeMode,
      );
      this.setStartTimeStamp(durationValue.from);
      this.setEndTimeStamp(durationValue.to);
    }

    try {
      const data = await this.callLogsApi(machineId);

      if (data.payload?.status === "success") {
        if (data.payload?.data?.result.length === 0) {
          this.enableLoadMore(false);
          this.setLogsList([]);
        } else {
          const streamLogs = data.payload?.data?.result?.[0]?.values || [];
          if (streamLogs.length == LogsLimit) {
            this.enableLoadMore(true);
          } else {
            this.enableLoadMore(false);
          }
          this.setLogsList(streamLogs);
        }
      }
    } catch (error) {
      ErrorReporter.collect(error, "Error in fetching logs");
    } finally {
      this.setLoading(false);
      this.setPageLoading(false);
      this.setLoadingSearchResult(false);
    }
  };

  fetchMoreLogs = async machineId => {
    this.setLoadingMoreLogs(true);
    if (this.logsList?.[this.logsList.length - 1]?.[0]) {
      this.setEndTimeStamp(
        parseInt(this.logsList[this.logsList.length - 1][0]),
      );
      this.setStartTimeStamp(this.selectedDuration.startTime);
    }
    this.enableLoadMore(false);
    try {
      const data = await this.callLogsApi(machineId);

      if (data.payload?.status === "success") {
        if (data.payload?.data?.result.length === 0) {
          this.enableLoadMore(false);
        } else {
          const streamLogs = data.payload?.data?.result?.[0]?.values || [];
          if (streamLogs.length == LogsLimit) {
            this.enableLoadMore(true);
          } else {
            this.enableLoadMore(false);
          }
          const logsMap = new Map<string, any>([
            ...this.logsList,
            ...streamLogs,
          ]);
          const logsArray = Array.from(logsMap);
          this.setLogsList(logsArray);
        }
      }
    } catch (error) {
      ErrorReporter.collect(error, "Error in fetching logs");
    } finally {
      this.setLoadingMoreLogs(false);
    }
  };

  fetchLogsWithTailenabled = async machineId => {
    if (this.logsList?.[0]?.[0]) {
      this.setStartTimeStamp(parseInt(this.logsList[0][0]));
    } else {
      this.setStartTimeStamp(this.selectedDuration.endTime);
    }
    this.setEndTimeStamp(getTimeStamp(dayjs(), this.timeMode));

    try {
      const data = await this.callLogsApi(machineId);

      if (data.payload?.status === "success") {
        if (data.payload?.data?.result.length === 0) {
        } else {
          const streamLogs = data.payload?.data?.result?.[0]?.values || [];
          if (streamLogs.length == LogsLimit) {
            // this.enableLoadMore(true);
          } else {
            // this.enableLoadMore(false);
          }
          this.lastLogTimeStamp = streamLogs[streamLogs.length - 1][0];

          const logsMap = new Map<string, any>([
            ...streamLogs,
            ...this.logsList,
          ]);
          const logsArray = Array.from(logsMap);
          this.setLogsList(logsArray);
        }
      }
    } catch (error) {
      ErrorReporter.collect(error, "Error in fetching logs");
    } finally {
    }
  };

  fetchLogStream = async machineId => {
    this.setLoadingStream(true);
    this.setLogsList([]);
    this.setStreamList([]);
    this.setSelectedStream("NONE");
    if (this.selectedDuration.value == "custom") {
      this.setStartTimeStamp(this.selectedDuration.startTime);
      this.setEndTimeStamp(this.selectedDuration.endTime);
    } else {
      const durationValue = getDuration(
        this.selectedDuration.value,
        this.timeMode,
      );

      this.setStartTimeStamp(durationValue.from);
      this.setEndTimeStamp(durationValue.to);
    }

    try {
      const data = await getLogStreamsAPI(
        {
          start: this.startTimeStamp,
          end: this.endTimeStamp,
        },
        machineId,
      );
      if (data.payload?.status === "success") {
        if (data.payload?.data.length > 0) {
          this.setStreamList(data.payload?.data);
          this.setSelectedStream(data.payload?.data[0]);
          await this.fetchLogs(machineId);
        } else {
          this.setStreamList([]);
        }
      }
    } catch (error) {
    } finally {
      this.setLoadingStream(false);
      this.setLoading(false);
    }
  };

  exportLogs = async machineId => {
    let response = {
      error: false,
      message: "",
      status: API_STATUS.PROCESSING,
    };
    this.setExportStatus(response);
    try {
      const data = await exportLogsAPI(
        {
          start: this.startTimeStamp,
          end: this.endTimeStamp,
          search: this.searchText,
          stream: this.selectedStream,
          limit: LogsLimit,
        },
        machineId,
      );
      if (data.statusCode == 200) {
        response = {
          error: false,
          message: data.message,
          status: API_STATUS.DONE,
        };
        this.setExportStatus(response);

        return {
          ...response,
          filePath: data.payload?.filePath,
        };
      } else {
        response = {
          error: true,
          message: data.message,
          status: API_STATUS.DONE,
        };
      }
    } catch (error) {
      response = {
        error: true,
        message: error.message,
        status: API_STATUS.DONE,
      };
    }
    this.setExportStatus(response);
    return { ...response, filePath: "" };
  };
}

export default new MachineLogsStore();
