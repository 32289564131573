import { API_BASE_URL } from "../../config/envConstants";
import { IAppServiceBody } from "../../storeManager/stores/appServicesStore";
import { CloudApiClient } from "../cloudApiClient";

const API_URL_SUFFIX = "/api/";
const API_VERSION = "v1";
const SERVIES_API_PREFIX = `/app-services`;
const API_URL = API_BASE_URL + API_URL_SUFFIX + API_VERSION;
const APP_SERVICE_BASE_URL = API_URL + SERVIES_API_PREFIX;

export const getAppServiceApi = () => {
  return CloudApiClient.httpGet(`${APP_SERVICE_BASE_URL}`);
};

export const addAppServiceApi = (body: IAppServiceBody) => {
  return CloudApiClient.httpPost(`${APP_SERVICE_BASE_URL}`, body);
};

export const deletAppServiceApi = (appId: string) => {
  return CloudApiClient.httpDelete(`${APP_SERVICE_BASE_URL}/${appId}`);
};

export const updateAppServiceApi = (body: IAppServiceBody, appId: string) => {
  return CloudApiClient.httpPut(`${APP_SERVICE_BASE_URL}/${appId}`, body);
};
