import { action, makeObservable, observable } from "mobx";
import { getLoggedInUserApi } from "../../api/authApis";
import {
  IGetCurrentUserRes,
  ILoggedInUser,
  IWhoamiAPIError,
} from "../../interfaces/authInterfaces";
import { ErrorReporter } from "../../libs/ErrorReporter";

type IAuthStoreFields = {
  isLoading: boolean;
  isUserPresent: boolean;
  loggedInUser: Partial<ILoggedInUser>;
  pageLoading: boolean;
  apiError: IWhoamiAPIError;
};

const initialValues: IAuthStoreFields = {
  isLoading: true,
  isUserPresent: false,
  loggedInUser: {},
  pageLoading: true,
  apiError: {
    show: false,
  },
};

class AuthStore {
  isLoading: boolean;
  isUserPresent: boolean;
  loggedInUser: Partial<ILoggedInUser>;
  pageLoading: boolean;
  apiError: IWhoamiAPIError;

  constructor() {
    this.reset();
    // makeAutoObservable(this, {}, { autoBind: true });
    makeObservable(this, {
      pageLoading: observable,
      isLoading: observable,
      setLoading: action,
      isUserPresent: observable,
      setLoggedInUser: action,
      setPageLoading: action,
      getPageLoading: observable,
      getUserFromServer: action,
      loggedInUser: observable,
    });
  }

  reset = () => {
    Object.keys(initialValues).forEach(key => {
      this[key] = initialValues[key];
    });
  };

  setLoading(value: boolean) {
    this.isLoading = value;
  }

  setPageLoading = (value: boolean) => {
    this.pageLoading = value;
  };

  getPageLoading = () => {
    return this.pageLoading;
  };

  setLoggedInUser = (values: ILoggedInUser) => {
    this.loggedInUser = values;
    this.isUserPresent = true;
    this.isLoading = false;
  };

  getUserFromServer = async (): Promise<ILoggedInUser> => {
    // this.isLoading = true;
    const RealTimeEvents = (await import("../../libs/RealTimeEvents")).default;
    const response: IGetCurrentUserRes = await getLoggedInUserApi().catch(
      error => {
        this.isLoading = false;
        RealTimeEvents.disconnect();
        throw error;
      },
    );
    if (!response?.payload?.emailAddress) throw new Error("user id not found");

    const user = response.payload;

    const formattedUser: ILoggedInUser = { 
      email: user?.emailAddress,
      username: user?.accounts?.displayName || "",
      is2faEnabled: user?.authIs2faEnabled || false,
      displayPicture: user.accounts.displayPictureMediaPath,
      name: user?.accounts?.name || "",
      sessionStartAt: user?.sessionStartAt,
      authenticationMethod: user?.authenticationMethod,
      channel: user?.channel || "",
    };
    this.setLoggedInUser(formattedUser);
    return formattedUser;
  };

  initialize = async () => {
    try {
      // this.isLoading = true;
      await this.getUserFromServer();
      this.apiError.show = false;
    } catch (error) {
      ErrorReporter.collect(
        error,
        "Error while fetching loggedIn user from server",
      );
      if (error.response?.status != 401) {
        this.apiError = {
          message: error.response?.message || error.message,
          show: true,
          status_code: error.response?.status || error.code,
        };
      }

      this.isUserPresent = false;
      this.isLoading = false;
    }
  };
}

export default new AuthStore();
