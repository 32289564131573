export interface IAddMachinePayload {
  displayPictureMediaPath: string;
}

export enum MachineModalSteps {
  STEP1 = 1,
  STEP2 = 2,
  STEP3 = 3,
  STEP4 = 4,
  STEP5 = 5,
  STEP6 = 6,
}

export enum MachineProvisionedStatus {
  SUCCESS = 1,
  FAILED = 0,
  ERROR = -1,
}
export interface IEventsData {
  name: string;
  applicationId: string;
  payload: any;
}

export interface ILogsData {
  name: string;
  applicationId: string;
  payload: any;
}
export interface IMachineSearch {
  tags: { key: string; value: string }[];
  searchText: string;
}

export interface IAddMachineReq {
  name: string;
  tags: string;
  provisioningCode: string;
}

export interface IUpdateMachineReqTag {
  key: string;
  value: string;
}

export interface IUpdateMachineReq {
  name?: string;
  tags?: IUpdateMachineReqTag[];
  provisioningCode?: string;
}

export interface IAddMachineRes {
  displayPictureMediaPath: string;
}

export interface IPinUnpinMachineRes {
  success: boolean;
  message: string;
  status: string;
  statusCode: number;
  errorCode: null;
  subErrors: null;
  payload: boolean;
}

// Machine Events Interface
export interface IMachineEventsRes {
  success: boolean;
  message: string;
  status: string;
  statusCode: number;
  errorCode: null;
  subErrors: null;
  payload: boolean;
}

export interface IMachinSystemInfo {
  id: number;
  machineId: string;
  hostname: string;
  osName: string;
  osVersion: string;
  osBuild: string;
  platform: string;
  platformRelease: string;
  arch: string;
  agentVersion: string;
  agentName: string;
  lastSysInfoUpdatedAt: string;
  createdAt: string;
}

export interface IWebSocketPing {
  eventCode: string;
  data: any;
  machineId: string;
}

export type IMachineStatus = IMachineStatusObj[];

export interface IMachineStatusObj {
  id: number;
  tenantCode: string;
  machineId: string;
  statusKey: string;
  statusValue: string;
  createdAt: string;
  updatedAt: string;
}

export interface INodeData {
  networkName: string;
  id?: number;
  nodeId: string;
  nodeKind: string;
  fullDNSName: string;
  dnsSuffix: string;
  ipv4Address: string;
  networkIpSubnet?: string;
  status?: number;
  leaseExpiresAt?: string;
  networksId?: number;
}

export interface IMachineContent {
  id: number;
  alias: string;
  tenantCode: string;
  machineId: string;
  accountId: number;
  userAccountId: number;
  name: string;
  arch: any;
  os: any;
  make: string;
  model: string;
  serialNumber: string;
  userConfigProfileId: number;
  systemConfigProfileId: number;
  userAppsProfileId: number;
  systemAppsProfileId: number;
  certId: string;
  certFingerprint: string;
  displayIconMediaPath: string;
  createdAt: string;
  updatedAt: string;
  tags: any[];
  machineIpConfigs: any[];
  machineTunnelConfig: ITunnelConfigs;
  pinnedMachine: any;
  telemetry: any;
  machineTemplate: IMachineTemplates;
  machineStatuses: IMachineStatus;
  lastActiveAt?: string;
  lastUptime?: string;
  networking?: INodeData;
  sysInfo?: IMachinSystemInfo;
}

export interface ITunnelConfigs {
  id: number;
  tenantCode: string;
  machineId: string;
  tunnelUrl: string;
  status: number;
  createdAt: string;
  updatedAt: string;
  enabled: boolean;
}

export interface IMachineLogsContent {
  machineId: string;
  machineModel: string;
  machineTags: string;
  machineMake: string;
  machineEnv: number;
  machineArch: string;
  machineOs: string;
  machineOsVersion: string;
  machineOsIsSigned: boolean;
  machineTz: string;
  agentAppId: string;
  agentAppVersion: string;
  agentVersion: string;
  applicationId: number;
  accountId: number;
  tenantCode: string;
  checksumSha1: string;
  timestamp: string;
  name: string;
  group: string;
  path: string;
  payload: string;
  level: string;
  system: boolean;
  size: number;
  tags: string;
  url: string;
}

export interface IMachineEventsPayload {
  content: IMachineContent[];
  pageSize: number;
  totalPages: number;
  totalRecordCount: number;
}

export interface IMachineTemplates {
  id: number;
  tenantCode: string;
  name: string;
  description: string;
  setupInstructions: string | Record<number, string>[];
  iconUrl: string;
  createdAt: string;
  generation: number;
}

export interface IVisualizationFieldsObject {
  type: string;
  timeInterval: string;
  aggregateFunction: string;
  allowedIntervals: string[];
}

export interface IVisualizationObject {
  network: Partial<IVisualizationFieldsObject>;
  cpu: Partial<IVisualizationFieldsObject>;
  load: Partial<IVisualizationFieldsObject>;
  disk: Partial<IVisualizationFieldsObject>;
  file: Partial<IVisualizationFieldsObject>;
  memory: Partial<IVisualizationFieldsObject>;
}
export interface IMachineMetrics {
  start: any;
  end: any;
  visualization: Partial<IVisualizationObject>;
  aggregateFunc: string;
}

export interface IMetricsRequest {
  metricsLabel: string;
  machineIdOrAlias: string;
  timeInterval: string;
  start: any;
  end: any;
  aggregateFunc?: string;
  timezone?: string;
}
export interface IMetricsResponse {
  errorCode: any;
  message: string;
  payload?: Payload;
  status: string;
  statusCode: number;
  subErrors: any;
  success: boolean;
}

export interface Payload {
  status: string;
  data: Data;
}

export interface Data {
  resultType: string;
  result: Result[];
}

export interface Result {
  metric: Metric;
  values: [number, any][];
}

export interface Metric {
  __name__: string;
  instance: string;
  job: string;
}

export interface IMetricsIntervalDurations {
  label: string;
  value: number;
}

export interface ISnapshotRequest {
  name: string;
  description: string;
  status: string;
  includes: string;
  machineId: string;
}

export interface IKeyValue {
  value: string;
  key: string;
}
export interface IDuration {
  label: string;
  value: string;
  interval: string[];
  from: string;
  to: string;
}

export interface IDecodedQuery {
  duration: IDuration;
  level: IKeyValue;
  group: IKeyValue;
  searchText: string;
  limit: number;
  page?: string;
}

export interface IMachineProvisionReq {
  provisionCode: string;
}
export interface IMachineProvisionRes {
  requestId: number;
  machineId: string;
}
export interface IMachineProvisionStatusRes {
  statusCode: IMachineProvisionStatus;
  status: number;
}

export enum IMachineProvisionStatus {
  PENDING = 0,
  FAILED = 10,
  COMPLETED = 20,
}

export interface IMachineStatusRes {
  success: boolean;
  message: string;
  status: string;
  statusCode: number;
  errorCode: any;
  subErrors: any;
  payload: IMachineStatusPayload;
}

export interface IMachineStatusPayload {
  content: IMachineStatusContent[] | [];
  totalPages: number;
  totalRecordCount: number;
  pageSize: number;
}

export interface IMachineStatusContent {
  id: number;
  tenantCode: string;
  machineId: string;
  statusKey: string;
  statusValue: string;
  createdAt: string;
  updatedAt: string;
}
export interface IMachineMonitoring {
  title: string;
  icon: React.ReactNode;
}

export interface IFirewallRule {
  id: number;
  tenantCode: string;
  machineId: string;
  name: string;
  direction: string;
  roles: string;
  protocol: string;
  allowPorts: string;
  status: number;
  createdAt: string;
  updatedAt: string;
}
