import { action, observable, makeObservable } from "mobx";
import { ErrorReporter } from "../../libs/ErrorReporter";
import { IMachineSearch } from "../../interfaces/machinesInterfaces";
import {
  addNetworkApi,
  addNodeApi,
  fetchNetworkDetails,
  fetchNodeDetails,
  fetchNodes,
  getNetworksApi,
  updateDomainApi,
  enableNodeApi,
  updateNetworkApi,
} from "../../api/networkingApis";
import { FirewallDirection } from "../../config/constants";
import { getFirewallRules } from "../../api/machinesApis";
import { customToast } from "../../utils/customToast";

export interface INetworkData {
  networkId: string;
  provider: string;
  ipSubnet: string;
  networkName: string;
  dnsName: string;
  dnsSuffix: string;
  isDefault: boolean;
  isNetworkKeyManaged: boolean;
}
export interface INodes {
  id: number;
  nodeId: string;
  nodeKind: string;
  dnsName: string;
  accountId: number;
  ipv4Address: string;
  networkId: number;
  leaseDuration: string;
  leaseExpiresAt: string;
  enrolledAt: any;
  status: number;
  createdAt: string;
  updatedAt: string;
  certFingerprint: any;
  domainName?: string;
  machineData: IMachineData;
}

export interface IMachineData {
  lastActiveAt: any;
  machineId: string;
  displayIconMediaPath: string;
  alias: string;
  firewallRules: IFirewallRule[];
}

export interface IFirewallRule {
  id: number;
  tenantCode: string;
  machineId: string;
  name: string;
  direction: string;
  roles: string;
  protocol: string;
  allowPorts: string;
  status: number;
  createdAt: string;
  updatedAt: string;
}

export interface IAddNodeReq {
  nodeId: string;
  nodeKind: string;
  networkDomain: string;
  dnsName: string;
  leaseDuration: string;
}

export interface IDomainList {
  id: 0;
  name: string;
  description: string;
  accountId: number;
  networkId: number;
  ipSubnet: string;
  createdAt: string;
  updatedAt: string;
  default: boolean;
  active: boolean;
}
export interface IAddNetworkReq {
  name: string;
  ipSubnet: string;
  dnsName: string;
  default: boolean;
}

export interface INetworkInfo {
  networkId: string;
  accountId: number;
  provider: string;
  ipSubnet: string;
  networkName: string;
  dnsName: string;
  dnsSuffix: string;
  createdAt: string;
  updatedAt: string;
  isDefault: boolean;
  isNetworkKeyManaged: boolean;
}

const initialValues = {
  networkId: "0",
  fetchingNetworkInfo: true,
  networkingPage: "home",
  offset: 1,
  limit: 10,
  nodeSearch: { tags: [], searchText: "" },
  modal: "",
  totalRecords: 0,
  networkInfo: {},
  domainsList: [],
  nodesList: [],
  nodeInfo: {},
  nodeId: "",
  inboundRules: [],
  outboundRules: [],
  fetchingNodes: true,
  fetchingIPs: true,
  fetchingDomains: true,
  fetchingNodeInfo: true,
  activeNetwork: {},
  activeNetworkName: "",
  fetchingInboundRules: true,
  fetchingOutboundRules: true,
  fetchingNetworks: true,
  networksList: [],
};

class NetworkingStore {
  fetchingNetworkInfo: boolean;
  networkingPage: string;
  offset: number;
  limit: number;
  networkId: string;
  hostSearch: IMachineSearch;
  nodeSearch: IMachineSearch;
  modal: string;
  totalRecords: number;
  networkInfo: Partial<INetworkInfo>;
  nodesList: Partial<INodes[]>;
  nodeInfo: Partial<INodes>;
  domainsList: Partial<IDomainList[]>;
  nodeId: string;
  inboundRules: Partial<IFirewallRule[]>;
  outboundRules: Partial<IFirewallRule[]>;
  fetchingNodes: boolean;
  fetchingIPs: boolean;
  fetchingDomains: boolean;
  activeNetwork: Partial<INetworkInfo>;
  fetchingNodeInfo: boolean;
  activeNetworkName: string;
  fetchingInboundRules: boolean;
  fetchingOutboundRules: boolean;
  networksList: INetworkData[];
  fetchingNetworks: boolean;

  constructor() {
    this.reset();
    makeObservable(this, {
      setFetchingNetworkInfo: action,
      fetchingNetworkInfo: observable,
      setNetworkingPage: action,
      networkingPage: observable,
      networkId: observable,
      setNetworkId: action,
      setNodeSearch: action,
      nodeSearch: observable,
      limit: observable,
      totalRecords: observable,
      offset: observable,
      setOffset: action,
      setModal: action,
      modal: observable,
      getNetworkInfo: action,
      networkInfo: observable,
      setNetworkInfo: action,
      nodesList: observable,
      getNodesList: action,
      setNodesList: action,
      nodeInfo: observable,
      setNodeInfo: action,
      getNodeInfo: action,
      domainsList: observable,
      getNetworksList: action,
      addNetwork: action,
      updateDomain: action,
      nodeId: observable,
      setNodeId: action,
      inboundRules: observable,
      outboundRules: observable,
      fetchInboundRules: action,
      fetchOutboundRules: action,
      fetchFirewallRules: action,
      fetchingNodes: observable,
      fetchingDomains: observable,
      activeNetwork: observable,
      setActiveDomain: action,
      fetchingNodeInfo: observable,
      setFetchingNodeInfo: action,
      activeNetworkName: observable,
      setActiveNetworkName: action,
      fetchingInboundRules: observable,
      fetchingOutboundRules: observable,
      networksList: observable,
      fetchNetworks: action,
      fetchingNetworks: observable,
    });
  }

  reset = () => {
    Object.keys(initialValues).forEach(key => {
      this[key] = initialValues[key];
    });
  };

  setNetworksList = (value: INetworkData[]) => {
    this.networksList = value;
  };

  setFetchingNetworksList = (value: boolean) => {
    this.fetchingNetworks = value
  }

  fetchNetworks = async () => {
    this.setFetchingNetworksList(true) 
    try {
      const res = await getNetworksApi();
      this.setNetworksList(res.payload.content);
    } catch (err) {
      ErrorReporter.collect(err, "Error while fetching networks list", {});
      customToast({
        msg: "Unable to fetch networks",
        toastType: "ERROR",
      });
    } finally {
      this.setFetchingNetworksList(false) 
    }
  };

  setNetworkId = (value: string) => {
    this.networkId = value;
  };

  getNetworksList = async (showLoader=true) => {
    if( this.networksList.length == 0 || showLoader){
      this.fetchingDomains = true;

    }
    try {
      const res = await getNetworksApi();
      if (res) {
        this.setNetworksList(res?.payload?.content || {});
      }
    } catch (error) {
      ErrorReporter.collect(error, "Error while fetching domains", {
        networkId: this.networkId,
      });
    } finally {
      this.fetchingDomains = false;
    }
  };

  getNetworkInfo = async (idx = this.networkId) => {
    this.setFetchingNetworkInfo(true);
    if (idx) {
      try {
        const res = await fetchNetworkDetails(`${idx}`);
        if (res) {
          this.setNetworkInfo(res?.payload || {});
        }
      } catch (error) {
        ErrorReporter.collect(
          error,
          "Error while fetching domain information",
          {
            networkId: idx,
          },
        );
      } finally {
        this.setFetchingNetworkInfo(false);
      }
    }
  };

  setNetworkInfo = (value: Partial<INetworkInfo>) => {
    this.networkInfo = value;
    this.activeNetwork = value;
  };

  getNodesList = async (networkId = this.networkInfo.networkId) => {
    this.setFetchingNodes(true);
    if (networkId) {
      try {
        const res = await fetchNodes(
          `${networkId}`,
          this.nodeSearch.searchText,
        );
        if (res) {
          this.setNodesList(res?.payload?.content);
        }
      } catch (error) {
        ErrorReporter.collect(error, "Error while fetching nodes list", {
          networkId: this.networkId,
        });
      }
      this.setFetchingNodes(false);
    }
  };

  setNodeId = value => {
    this.nodeId = value;
  };

  getNodeInfo = async (nodeId = this.nodeId) => {
    this.setFetchingNodeInfo(true);
    if (nodeId) {
      try {
        const res = await fetchNodeDetails(`${nodeId}`);
        if (res) {
          this.setNodeInfo(res?.payload || []);
        }
      } catch (error) {
        ErrorReporter.collect(error, "Error while fetching node information", {
          networkId: this.networkId,
        });
      } finally {
        this.setFetchingNodeInfo(false);
      }
    }
  };

  addNodeByNetworkId = async (
    body: Partial<IAddNodeReq>,
    fetchNodes = true,
  ) => {
    try {
      const res = await addNodeApi(body);
      if (fetchNodes) {
        this.getNodesList();
      }
      return res;
    } catch (error) {
      ErrorReporter.collect(error, "Error while adding node", {
        nodeinfo: body.nodeId,
        dnsName: body.dnsName,
      });
      throw error;
    }
  };

  enableNode = async (body: Partial<IAddNodeReq>, fetchNodes = true) => {
    try {
      const res = await enableNodeApi(body);
      if (fetchNodes) {
        this.getNodesList();
      }
      return res;
    } catch (error) {
      ErrorReporter.collect(error, "Error while adding node", {
        nodeinfo: body.nodeId,
        dnsName: body.dnsName,
      });
      throw error;
    }
  };

  addNetwork = async (body: Partial<IAddNetworkReq>) => {
    try {
      const res = await addNetworkApi(body);
      this.getNetworksList();
      return res;
    } catch (error) {
      ErrorReporter.collect(error, "Error while adding network");
      throw error;
    }
  };

  updateNetwork = async (body: Partial<IAddNetworkReq>) => {
    try {
      const res = await updateNetworkApi(body, this.networkId);
      this.getNetworkInfo();
      return res;
    } catch (error) {
      ErrorReporter.collect(error, "Error while updating network");
      throw error;
    }
  };

  updateDomain = async (body: Partial<IAddNetworkReq>) => {
    try {
      const res = await updateDomainApi(body, this.networkInfo.networkId);
      this.getNetworkInfo();
      return res;
    } catch (error) {
      ErrorReporter.collect(error, "Error while adding domain");
    }
  };

  fetchInboundRules = async (machine_id_alias: string) => {
    this.fetchingInboundRules = true;
    try {
      const res = await getFirewallRules(
        machine_id_alias,
        FirewallDirection.INBOUND,
      );

      if (res?.success) {
        this.setInboundRules(res?.payload?.content);
        return res?.payload?.content;
      }
    } catch (error) {
      ErrorReporter.collect(
        error,
        "Error while fetching firewall inbound rules",
        {
          nodeId: this.nodeId,
        },
      );
    } finally {
      this.fetchingInboundRules = false;
    }
  };

  fetchOutboundRules = async (machine_id_alias: string) => {
    this.fetchingOutboundRules = true;
    try {
      const res = await getFirewallRules(
        machine_id_alias,
        FirewallDirection.OUTBOUND,
      );
      if (res?.success) {
        this.setOutboundRules(res?.payload?.content);
        return res?.payload?.content;
      }
    } catch (error) {
      ErrorReporter.collect(
        error,
        "Error while fetching firewall outbound rules",
        {
          nodeId: this.nodeId,
        },
      );
    } finally {
      this.fetchingOutboundRules = false;
    }
  };

  fetchFirewallRules = async (machine_id_alias: string) => {
    try {
      await this.fetchInboundRules(machine_id_alias);
      await this.fetchOutboundRules(machine_id_alias);
    } catch (error) {
      ErrorReporter.collect(error, "Error while fetching firewall rules", {
        nodeId: this.nodeId,
      });
    }
  };

  setInboundRules = (value: Partial<IFirewallRule[]>) => {
    this.inboundRules = value;
  };

  setOutboundRules = (value: Partial<IFirewallRule[]>) => {
    this.outboundRules = value;
  };

  setNodeInfo = (value: Partial<INodes>) => {
    this.nodeInfo = value;
  };

  setNodesList = (value: Partial<INodes[]>) => {
    this.nodesList = value;
  };

  setOffset = (offset: number) => {
    this.offset = offset;
  };

  setFetchingNetworkInfo = (value: boolean) => {
    this.fetchingNetworkInfo = value;
  };

  setFetchingNodeInfo = (value: boolean) => {
    this.fetchingNodeInfo = value;
  };

  setActiveNetworkName = (value: string) => {
    this.activeNetworkName = value;
  };

  setModal = (value: string) => {
    this.modal = value;
  };

  setNodeSearch = value => {
    this.nodeSearch = value;
    this.getNodesList();
  };

  setHostSearch = value => {
    this.hostSearch = value;
  };

  setNetworkingPage = (value: string) => {
    this.networkingPage = value;
  };

  setFetchingNodes = (value: boolean) => {
    this.fetchingNodes = value;
  };

  setActiveDomain = value => {
    if (`${value?.id}` !== this.networkId) {
      this.nodesList = [];
      this.setNetworkInfo({});
    }
    this.activeNetwork = value;
  };
}

export default new NetworkingStore();
