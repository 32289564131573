import { CloudApiClient } from "../cloudApiClient";
import { MACHINES_API_URL } from "../constants";

export const getLogsAPI = (body: any, machineId: string): any => {
  return CloudApiClient.httpPost(
    `${MACHINES_API_URL}/${machineId}/logs/query`,
    body,
  );
};

export const exportLogsAPI = (body: any, machineId: string): any => {
  return CloudApiClient.httpPost(
    `${MACHINES_API_URL}/${machineId}/logs/export`,
    body,
  );
};

export const getLogStreamsAPI = (body: any, machineId: string): any => {
  return CloudApiClient.httpPost(
    `${MACHINES_API_URL}/${machineId}/logs/streams`,
    body,
  );
};
